import { Fragment } from "react/jsx-runtime";

import { Icon, IconType } from "components/common/basic";
import { TTailwindString, tw } from "utils/tw";

interface Props {
  icon: IconType;
  heading: string;
  features: {
    label: string;
    plans: (boolean | string)[];
  }[];
  gridCols: TTailwindString;
}

export default ({ icon, heading, features, gridCols }: Props): JSX.Element => (
  <div className={tw("space-y-4")}>
    <div className={tw("flex", "space-x-2")}>
      <Icon icon={icon} isFilled size={20} color="text-gray-900" />

      <h3 className={tw("font-bold")}>{heading}</h3>
    </div>

    <div
      className={tw(
        "grid",
        gridCols,
        "gap-y-px",
        "bg-deepBlue-50",
        "border-y",
        "border-deepBlue-50"
      )}
    >
      {features.map(({ label, plans }) => (
        <Fragment key={label}>
          <span
            className={tw(
              "py-4",
              "bg-white",
              "flex",
              "items-center",
              "text-deepBlue-900"
            )}
          >
            {label}
          </span>

          {plans.map((planFeature, index) => (
            <span
              key={index}
              className={tw(
                "py-4",
                "bg-white",
                "flex",
                "items-center",
                "justify-center",
                "text-center",
                "text-deepBlue-900",
                "whitespace-pre-wrap"
              )}
            >
              {typeof planFeature === "string" ? (
                planFeature
              ) : planFeature ? (
                <Icon
                  icon="HiCheckCircle"
                  isFilled
                  size={20}
                  color="text-success"
                />
              ) : (
                <Icon icon="HiX" isFilled size={20} color="text-deepBlue-100" />
              )}
            </span>
          ))}
        </Fragment>
      ))}
    </div>
  </div>
);
