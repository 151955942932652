import { useContext } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import { nb } from "date-fns/locale/nb";

import { AuthContext } from "providers/Authentication";
import { tw } from "utils/tw";

import DatePickerContainer from "./DatePickerContainer";

registerLocale("nb", nb);

interface CalendarProps {
  startDate: Date | null;
  endDate: Date | null;
  monthsShown: number;
  onCancel: () => void;
  setDateRange: ([start, end]: [start: Date | null, end: Date | null]) => void;
  submitDateFilter: () => void;
}

export default ({
  startDate,
  endDate,
  monthsShown,
  onCancel,
  setDateRange,
  submitDateFilter,
}: CalendarProps) => {
  const { session } = useContext(AuthContext);

  return (
    <DatePicker
      selectsRange
      inline
      calendarClassName={tw(
        "border-none",
        "mt-9",
        "mx-auto",
        "mb-10",
        "xl:my-0",
        "xl:ml-5"
      )}
      locale={session?.user.language.toLocaleLowerCase()}
      calendarStartDay={1}
      shouldCloseOnSelect={false}
      monthsShown={monthsShown}
      selected={startDate}
      startDate={startDate ?? undefined}
      endDate={endDate ?? undefined}
      onClickOutside={onCancel}
      onChange={(update) => {
        setDateRange(update);
      }}
      calendarContainer={(props) => (
        <DatePickerContainer
          {...props}
          startDate={startDate}
          endDate={endDate}
          onCancel={onCancel}
          setDateRange={setDateRange}
          submitDateFilter={submitDateFilter}
        />
      )}
    />
  );
};
