import { TFunction } from "i18next";
import * as Yup from "yup";

import getAlphanumericValueFromString from "./getAlphanumericValueFromString";

export const phoneValidationSchema = (t: TFunction): Yup.StringSchema =>
  Yup.string().matches(/(\d|\+)+$/, {
    excludeEmptyString: true,
    message: t(
      "common:error.phone.format",
      "Incorrect phone format (only + and 0-9)"
    ),
  });

export const emailValidationSchema = (t: TFunction): Yup.StringSchema =>
  Yup.string()
    .required(t("common:error.email.required", "An email address is required"))
    .email(t("common:error.email.format", "Incorrect email format"));

export const passwordValidationSchema = (t: TFunction): Yup.StringSchema =>
  Yup.string()
    .required(t("common:error.password.required", "A password is required"))
    .min(
      6,
      t(
        "common:error.password.passwordTooShort",
        "Minimum 6 characters, please!"
      )
    )
    .max(
      64,
      t(
        "common:error.password.passwordTooLong",
        "No more than 64 characters, please!"
      )
    );

export const confirmationCodeSchema = (t: TFunction): Yup.StringSchema =>
  Yup.string()
    .required(
      t(
        "common:error.confirmationCode.required",
        "Confirmation code is required"
      )
    )
    .matches(
      /[0-9]+/,
      t(
        "common:error.confirmationCode.format",
        "Confirmation should only include numbers"
      )
    )
    .length(
      5,
      t(
        "common:error.confirmationCode.length",
        "Confirmation code must consist of 5 digits"
      )
    );

export const invoiceStartingNumberSchema = (t: TFunction): Yup.NumberSchema =>
  Yup.number()
    .min(
      1,
      t(
        "common:error.invoiceStartingNumber.min",
        "The invoice number can not be below 1"
      )
    )
    .max(
      9999_9999,
      t(
        "common:error.invoiceStartingNumber.max",
        "The invoice number must be between 1 and 8 digits"
      )
    )
    .integer(
      t(
        "common:error.invoiceStartingNumber.integer",
        "The invoice number can not include punctuation"
      )
    )
    .typeError(
      t(
        "common:error.invoiceStartingNumber.required",
        "An invoice number is required"
      )
    )
    .required(
      t(
        "common:error.invoiceStartingNumber.required",
        "An invoice number is required"
      )
    );

export const bankAccountNumberValidationSchema = (
  t: TFunction
): Yup.StringSchema =>
  Yup.string()
    .transform(getAlphanumericValueFromString)
    .min(
      11,
      t(
        "common:error.bankAccountNumber.min",
        "The bank account number must consist of at least 11 characters"
      )
    )
    .max(
      34,
      t(
        "common:error.bankAccountNumber.max",
        "The bank account number must consist of no more than 34 characters"
      )
    )
    .typeError(
      t(
        "common:error.bankAccountNumber.required",
        "A bank account number is required"
      )
    )
    .required(
      t(
        "common:error.bankAccountNumber.required",
        "A bank account number is required"
      )
    );
