import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import StripeHandler from "./StripeHandler";

export default (): JSX.Element => {
  const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const stripePromise = stripeKey ? loadStripe(stripeKey) : null;

  return (
    <Elements stripe={stripePromise}>
      <StripeHandler />
    </Elements>
  );
};
