import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { CheckBox, Divider, Input, Select } from "components/common/basic";
import { PopupWrapper } from "components/common/popups";
import { BankAccountFragment } from "graphql/fragments";
import { useCreateBankAccountMutation } from "graphql/mutations";
import { useFreelanceProfilesQuery } from "graphql/queries";
import { CurrencyEnum, FreelanceTypeEnum } from "graphql/types";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import {
  bankAccountNumberValidationSchema,
  getAlphanumericValueFromString,
} from "utils";
import { tw } from "utils/tw";

interface FormValues {
  freelanceProfileId: string;
  accountNumber: string;
  currency: CurrencyEnum;
  swiftCode?: string;
  default: boolean;
}

interface Props {
  hasPrevious: boolean;
  freelanceProfileId?: string;
  onSuccess?: (bankAccount: BankAccountFragment) => void;
  showProfileSelector?: boolean;
}

export default ({
  freelanceProfileId,
  onSuccess,
  showProfileSelector = false,
  ...props
}: Props): JSX.Element => {
  const [isOrg, setIsOrg] = useState<boolean>();
  const [isInternational, setIsInternational] = useState<boolean>();
  const { freelanceProfiles, isLoading } = useFreelanceProfilesQuery();
  const { createBankAccount } = useCreateBankAccountMutation();

  const { closeAllPopups } = useContext(PopupContext);
  const { t } = useTranslation("common");
  const { reportErrors } = useErrorLogger();

  const errorMessages = {
    freelanceProfileId: t(
      "popup.addBankAccount.freelanceProfileId.error.required",
      "A connected payout profile is required"
    ),
    accountNumber: t(
      "popup.addBankAccount.accountNumber.error.invalid",
      "The bank account number should only contain numbers"
    ),
    currency: t(
      "popup.addBankAccount.currency.error.required",
      "A currency is required"
    ),
    swiftCode: t(
      "popup.addBankAccount.swiftCode.swiftCode.length",
      "The swift code must be between 8 and 11 characters"
    ),
  };
  const validationSchema = Yup.object({
    freelanceProfileId: Yup.string()
      .required(errorMessages.freelanceProfileId)
      .oneOf(freelanceProfiles.map(({ id }) => id)),

    currency: Yup.string()
      .required(errorMessages.currency)
      .oneOf(isOrg ? Object.values(CurrencyEnum) : [CurrencyEnum.Nok]),
    swiftCode: Yup.string()
      .transform((value) => (value ? value : undefined))
      .min(8, errorMessages.swiftCode)
      .max(11, errorMessages.swiftCode),
    accountNumber: bankAccountNumberValidationSchema(t)
      .required()
      .when("currency", {
        is: CurrencyEnum.Nok,
        then: (schema) => schema.matches(/^\d+$/, errorMessages.accountNumber),
      }),

    default: Yup.boolean().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    resetField,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  reportErrors(errors);
  const freelanceProfileIdValue = watch("freelanceProfileId");

  const onSubmit = ({ freelanceProfileId, ...values }: FormValues) =>
    createBankAccount(
      freelanceProfileId,
      {
        ...values,
        accountNumber: isInternational
          ? values.accountNumber
          : getAlphanumericValueFromString(values.accountNumber),
        default: [true, "true"].includes(values.default ?? false),
      },
      (bankAccount) => {
        if (onSuccess && bankAccount) onSuccess(bankAccount);

        closeAllPopups();
      }
    );

  useEffect(() => {
    const freelanceProfile = freelanceProfiles.find(
      (profile) => profile.id === freelanceProfileIdValue
    );
    if (!freelanceProfile) return;

    setIsInternational(freelanceProfile.address?.country !== "NO");

    const newIsOrg =
      freelanceProfile.freelanceType === FreelanceTypeEnum.Organization;
    if (isOrg !== newIsOrg) {
      resetField("currency", { defaultValue: CurrencyEnum.Nok });

      setIsOrg(
        freelanceProfile?.freelanceType === FreelanceTypeEnum.Organization
      );
    }
  }, [freelanceProfileIdValue, freelanceProfiles.length]);

  const formId = "addBankAccountForm";

  return (
    <PopupWrapper
      {...props}
      title={t("popup.addBankAccount.title", "Add Bank Account")}
      variant="popup"
      footerActions={[
        {
          id: "add_bank_account-cancel",
          onClick: closeAllPopups,
          variant: "tertiary",
          label: t("popup.addBankAccount.cancel", "Cancel"),
        },
        {
          id: "add_bank_account-add_bank_account",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("popup.addBankAccount.submit", "Add"),
        },
      ]}
    >
      <form
        className={tw("w-full", "space-y-4")}
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className={tw("text-lg", "font-bold")}>
          {t(
            "popup.addBankAccount.form.accountDetailsSection.title",
            "Account details"
          )}
        </h2>

        <div className={tw("flex", "space-x-4")}>
          <Input
            className={tw("w-2/3")}
            id="accountNumber"
            {...register("accountNumber")}
            label={t(
              "popup.addBankAccount.form.accountNumber.label",
              "Account number"
            )}
            placeholder={t(
              "popup.addBankAccount.form.accountNumber.placeholder",
              "Number"
            )}
            errorMessage={errors.accountNumber?.message}
          />

          <Controller
            control={control}
            name="currency"
            defaultValue={CurrencyEnum.Nok}
            render={({ field }) => (
              <Select
                className={tw("w-1/3")}
                id={field.name}
                name={field.name}
                label={t(
                  "popup.addBankAccount.form.currency.label",
                  "Currency"
                )}
                options={
                  isOrg
                    ? Object.values(CurrencyEnum).map((value) => ({
                        label: value,
                        value,
                      }))
                    : [
                        {
                          label: CurrencyEnum.Nok,
                          value: CurrencyEnum.Nok,
                        },
                      ]
                }
                value={field.value}
                onChange={field.onChange}
                errorMessage={errors.currency?.message}
              />
            )}
          />
        </div>

        <Input
          id="swiftCode"
          {...register("swiftCode")}
          label={t("popup.addBankAccount.form.swiftCode.label", "BIC / SWIFT")}
          placeholder={t(
            "popup.addBankAccount.form.swiftCode.placeholder",
            "Number"
          )}
          hint={t("popup.addBankAccount.form.swiftCode.hint", "Optional")}
          errorMessage={errors.swiftCode?.message}
        />

        <input
          {...register("freelanceProfileId")}
          defaultValue={freelanceProfileId ?? freelanceProfiles[0].id}
          style={{ display: "none" }}
        />

        {!isLoading && showProfileSelector && (
          <>
            <Divider />

            <h2 className={tw("my-4", "text-lg", "font-bold")}>
              {t(
                "popup.addBankAccount.form.payoutProfileSection.title",
                "Payout profile attachment"
              )}
            </h2>

            <p>
              {t(
                "popup.addBankAccount.form.payoutProfileSection.heading",
                "Please provide the payout profile the bank account should be attached to."
              )}
            </p>

            <Controller
              control={control}
              name="freelanceProfileId"
              defaultValue={freelanceProfileId ?? freelanceProfiles[0].id}
              render={({ field }) => (
                <Select
                  id={field.name}
                  name={field.name}
                  label={t(
                    "popup.addBankAccount.form.freelanceProfileId.label",
                    "Payout profile"
                  )}
                  options={freelanceProfiles.map((profile) => ({
                    label:
                      profile.freelanceType === FreelanceTypeEnum.Organization
                        ? `${profile.organizationName} (${profile.organizationNumber})`
                        : `${profile.firstName} ${profile.lastName}`,
                    value: profile.id,
                  }))}
                  value={field.value}
                  onChange={field.onChange}
                  errorMessage={errors.freelanceProfileId?.message}
                />
              )}
            />
          </>
        )}

        <Divider />

        <CheckBox
          id="default"
          {...register("default")}
          label={t(
            "popup.addBankAccount.form.makeDefault.label",
            "Make default"
          )}
          errorMessage={errors.default?.message}
        />
      </form>
    </PopupWrapper>
  );
};
