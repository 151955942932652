/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { CreateStripeSetupIntentInput, StripeSetupIntent } from "graphql/types";

const CREATE_STRIPE_SETUP_INTENT = gql`
  mutation ($input: CreateStripeSetupIntentInput!) {
    createStripeSetupIntent(input: $input) {
      setupIntent {
        clientSecret
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  setupIntent?: Pick<StripeSetupIntent, "clientSecret"> | null;
  errors: ErrorFragment[];
}

interface CreateStripeSetupIntentMutation {
  createStripeSetupIntent: Payload | null;
}

export const useCreateStripeSetupIntentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateStripeSetupIntentMutation,
    { input: CreateStripeSetupIntentInput }
  >(CREATE_STRIPE_SETUP_INTENT);

  const createStripeSetupIntent = (
    input: CreateStripeSetupIntentInput = {},
    onSuccess?: (clientSecret: StripeSetupIntent["clientSecret"]) => void
  ) =>
    mutation({
      variables: { input },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createStripeSetupIntent?.errors ?? [];
        const clientSecret =
          data?.createStripeSetupIntent?.setupIntent?.clientSecret;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && clientSecret && onSuccess(clientSecret),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createStripeSetupIntent,
    isLoading: loading,
    clientSecret: data?.createStripeSetupIntent?.setupIntent?.clientSecret,
    errors: data?.createStripeSetupIntent?.errors ?? [],
  };
};
