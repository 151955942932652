import { PropsWithChildren } from "react";

import { Link } from "components/common/basic";
import { IdentityCountryCodeEnum } from "graphql/types";
import { useRoutePaths } from "utils";
import { TTailwindString } from "utils/tw";

interface Props {
  state: string;
  countryOptions?: IdentityCountryCodeEnum[];
  openSeparate?: boolean;
  className?: TTailwindString;
}

export default ({
  state,
  countryOptions = [IdentityCountryCodeEnum.No],
  openSeparate = false,
  className,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  const getRoutePath = useRoutePaths();

  const getURL = () => {
    const { origin } = new URL(window.location.href);
    const redirectUri = `${origin}${getRoutePath(
      { module: "common", routeName: "CRIIPTO_CALLBACK" },
      { isGeneric: true }
    )}`;
    const responseType = "code";
    const responseMode = "fragment";
    const acrValues = countryOptions
      .map(getAcrFromCountry)
      .filter(Boolean)
      .join(" ");
    const scope = "openid ssn";

    return (
      `https://${process.env.REACT_APP_CRIIPTO_DOMAIN}/oauth2/authorize?` +
      `response_type=${responseType}&` +
      `response_mode=${responseMode}&` +
      `client_id=${process.env.REACT_APP_CRIIPTO_CLIENT_ID}&` +
      `acr_values=${acrValues}&` +
      `redirect_uri=${redirectUri}&` +
      `scope=${scope}&` +
      `state=${state}`
    );
  };

  return (
    <Link
      id="criipto_link-go_to_criipto"
      to={getURL()}
      isExternal
      openSeparate={openSeparate}
      unstyled
      className={className}
    >
      {children}
    </Link>
  );
};

const getAcrFromCountry = (country: IdentityCountryCodeEnum) => {
  switch (country) {
    case "NO":
      return "urn:grn:authn:no:bankid";

    case "SE":
      return "urn:grn:authn:se:bankid:same-device urn:grn:authn:se:bankid:another-device:qr";

    case "DK":
      return "urn:grn:authn:dk:mitid:low urn:grn:authn:dk:mitid:substantial";

    case "FI":
      return "urn:grn:authn:fi:bank-id";

    default:
      return null;
  }
};
