import { TierFragment } from "graphql/fragments";
import { PriceIntervalEnum } from "graphql/types";
import { useTranslation } from "translations";
import { getProductName } from "utils";
import { tw } from "utils/tw";

import TierAction from "../TierAction";

interface Props {
  tier: TierFragment;
  billingCycle?: string;
  currentTier?: TierFragment;
  onSelect?: (price: TierFragment) => void;
}
export default ({
  tier,
  billingCycle,
  currentTier,
  onSelect,
}: Props): JSX.Element => {
  const { t } = useTranslation("login");

  const price =
    tier.amount === -1
      ? null
      : tier.interval === PriceIntervalEnum.Month
        ? tier.amount / 100
        : tier.amount / 100 / 12;

  return (
    <div
      className={tw(
        "p-6",
        "pb-8",
        "flex",
        "flex-col",
        "space-y-9",
        "bg-white",
        "rounded-lg"
      )}
    >
      <h2 className={tw("text-deepBlue-900", "text-2xl", "font-extrabold")}>
        {getProductName(tier.type, t)}
      </h2>

      <div className={tw("space-y-1", { "opacity-0": tier.amount === -1 })}>
        <p className={tw("text-deepBlue-900", "text-4xl", "font-extrabold")}>
          {price},-{" "}
          <span className={tw("text-lg", "font-regular")}>{tier.currency}</span>
        </p>

        <p className={tw("h-4", "text-deepBlue-700", "text-xs")}>
          {billingCycle}
        </p>
      </div>

      <TierAction currentTier={currentTier} tier={tier} onSelect={onSelect} />
    </div>
  );
};
