import { useContext } from "react";

import { FeatureTypeEnum, TeamRoleEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { snakeCaseToCamelCase } from "utils/convertSnakeCase";
import { tw } from "utils/tw";

import { PopupVariant, PopupWrapper } from "./PopupWrapper";

interface Props {
  feature: "MEMBER_LIMIT" | FeatureTypeEnum;

  // Popup props
  hasPrevious: boolean;
  variant: PopupVariant;
  onSuccess?: () => void;
}

export default ({ feature, ...props }: Props): JSX.Element => {
  const { session } = useContext(AuthContext);
  const isMember = session?.role === TeamRoleEnum.Member;

  const { closeOnePopup, openPopup } = useContext(PopupContext);
  const { t } = useTranslation("common");

  return (
    <PopupWrapper
      {...props}
      variant="popup"
      title={t("popup.upgradeRequired.title", "Upgrade")}
      footerActions={[
        {
          id: "upgrade_required_popup-cancel",
          onClick: closeOnePopup,
          variant: "tertiary",
          label: t("popup.upgradeRequired.cancel", "Cancel"),
        },
        // TODO: Show member button when BE is available
        ...(!isMember
          ? [
              {
                id: "upgrade_required_popup-confirm" as const,
                onClick: isMember
                  ? () => alert("Pending BE")
                  : () =>
                      openPopup({ id: "UpdateSubscription", props: {} }, true),
                variant: "primary" as const,
                label: isMember
                  ? t("popup.upgradeRequired.requestUpgrade", "Request upgrade")
                  : t(
                      "popup.upgradeRequired.upgrade",
                      "View features and upgrade"
                    ),
              },
            ]
          : []),
      ]}
    >
      <p className={tw("whitespace-pre-wrap", "text-lg")}>
        <Trans
          ns="common"
          i18nKey={`popup.upgradeRequired.body_${snakeCaseToCamelCase(feature)}`}
          defaults={
            "You need to <strong>upgrade</strong> to be able to use this feature!{{ memberSection }}\n\nBy upgrading, you'll have access to advanced features."
          }
          components={{
            strong: <strong className={tw("font-bold")} />,
          }}
          values={{
            memberSection: isMember
              ? t(
                  "popup.upgradeRequired.body.requestAccess",
                  "\nSend a request to your admin(s) to upgrade."
                )
              : "",
          }}
        />
      </p>
    </PopupWrapper>
  );
};
