/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  DOCUMENT_FRAGMENT,
  DocumentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { SignatureAttributes } from "graphql/types";

const CREATE_SIGNATURES = gql`
  mutation ($id: ID!, $attributes: [SignatureAttributes!]!) {
    createSignatures(input: { documentId: $id, attributes: $attributes }) {
      document {
        ...DocumentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  document: DocumentFragment;
  errors: ErrorFragment[];
}

interface CreateSignaturesMutation {
  createSignatures: Payload | null;
}

export const useCreateSignaturesMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateSignaturesMutation,
    { id: string; attributes: SignatureAttributes[] }
  >(CREATE_SIGNATURES);

  const createSignatures = (
    variables: { id: string; attributes: SignatureAttributes[] },
    onSuccess?: () => void
  ) =>
    mutation({ variables })
      .then(({ data, errors }) => {
        const dataErrors = data?.createSignatures?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createSignatures,
    isLoading: loading,
    errors: data?.createSignatures?.errors ?? [],
  };
};
