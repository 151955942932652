import React, { createContext, useContext, useEffect } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { StripeElementLocale, loadStripe } from "@stripe/stripe-js";

import { Spinner } from "components/common/basic";
import { useCreateStripeSetupIntentMutation } from "graphql/mutations";
import { IntlContext } from "providers/i18n";
import { stripeUtils } from "utils";

interface ContextProps {
  clientSecret: string;
}
export const StripeContext = createContext<ContextProps>({ clientSecret: "" });

interface Props {
  children?: React.ReactNode;
}
export default ({ children }: Props): JSX.Element => {
  const { currentLocale } = useContext(IntlContext);

  const { clientSecret, createStripeSetupIntent } =
    useCreateStripeSetupIntentMutation();

  const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const stripePromise = stripeKey ? loadStripe(stripeKey) : null;

  useEffect(() => {
    createStripeSetupIntent();
  }, []);

  if (!clientSecret) return <Spinner />;

  return (
    <StripeContext.Provider value={{ clientSecret }}>
      <Elements
        options={{
          clientSecret,
          appearance: stripeUtils.appearance,
          locale: currentLocale.toLowerCase() as StripeElementLocale,
        }}
        stripe={stripePromise}
      >
        {children}
      </Elements>
    </StripeContext.Provider>
  );
};
