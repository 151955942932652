import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { storage } from "utils";

export default (): null => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // const referrer =
    //   searchParams.get("referrer") ??
    //   searchParams.get("gclid") ??
    //   searchParams.get("fbclid");
    // if (referrer)
    //   Cookie.set("referrer", JSON.stringify({ referrer }), { expires: 7 });

    // const occupation = searchParams.get("occupation");
    // if (occupation) storage.setSession("occupation", occupation);

    const feature = searchParams.get("feature");
    if (feature) storage.setLocal("feature", feature);
  }, [searchParams]);

  return null;
};
