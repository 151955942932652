import { gql } from "@apollo/client";

import { CheckList, CheckRequest } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";
import { CRC_PURPOSE_FRAGMENT, CrcPurposeFragment } from "./crcPurpose";
import { DEPARTMENT_FRAGMENT, DepartmentFragment } from "./department";
import { NAME_FRAGMENT, NameFragment } from "./name";
import {
  SIMPLIFIED_ACCOUNT_FRAGMENT,
  SimplifiedAccountFragment,
} from "./simplifiedAccount";

export const CHECK_REQUEST_FRAGMENT = gql`
  fragment CheckRequestFragment on CheckRequest {
    id
    type
    status
    account {
      ...SimplifiedAccountFragment
    }
    files {
      ...AttachmentFragment
    }
    checkLists {
      id
      email
      name {
        ...NameFragment
      }
      alreadyEmployed
      temporaryJob
      jobDescription
      jobRole
      phone
      employedOn
      department {
        ...DepartmentFragment
      }
      recentlyDoneThisCheck
    }
    contactName
    contactPhoneNumber
    contactEmail
    organizationName
    organizationNumber
    message
    crcPurpose {
      ...CrcPurposeFragment
    }
    previewPurposeLetterPath
    eLearningCourse
    checkTypes
    countries
    includeCheckedCandidates
  }
  ${ATTACHMENT_FRAGMENT}
  ${DEPARTMENT_FRAGMENT}
  ${SIMPLIFIED_ACCOUNT_FRAGMENT}
  ${CRC_PURPOSE_FRAGMENT}
  ${NAME_FRAGMENT}
`;

interface CheckRequestChecklist
  extends Pick<
    CheckList,
    | "id"
    | "email"
    | "name"
    | "alreadyEmployed"
    | "temporaryJob"
    | "employedOn"
    | "jobDescription"
    | "jobRole"
    | "phone"
    | "recentlyDoneThisCheck"
  > {
  name: NameFragment;
  department?: DepartmentFragment;
}

export interface CheckRequestFragment
  extends Pick<
    CheckRequest,
    | "id"
    | "type"
    | "status"
    | "files"
    | "contactName"
    | "contactPhoneNumber"
    | "contactEmail"
    | "organizationName"
    | "organizationNumber"
    | "message"
    | "crcPurpose"
    | "previewPurposeLetterPath"
    | "eLearningCourse"
    | "checkTypes"
    | "countries"
    | "includeCheckedCandidates"
  > {
  crcPurpose?: CrcPurposeFragment;
  account: SimplifiedAccountFragment;
  checkLists: CheckRequestChecklist[];
  files: AttachmentFragment[];
}
