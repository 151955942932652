import { Icon, IconType } from "components/common/basic";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  email: string;
  organization: string;
  address?: string;
}

export default ({ email, organization, address }: Props): JSX.Element => {
  const { t } = useTranslation("common");

  const listItems: {
    icon: IconType;
    label: string;
    value: string;
  }[] = [
    {
      icon: "HiMail",
      label: t(
        "gateKeeper.checkout.accountDetails.listItem_email.label",
        "Email"
      ),
      value: email,
    },
    {
      icon: "HiOfficeBuilding",
      label: t(
        "gateKeeper.checkout.accountDetails.listItem_organization.label",
        "Organisation"
      ),
      value: organization,
    },
    ...(address
      ? [
          {
            icon: "HiLocationMarker" as const,
            label: t(
              "gateKeeper.checkout.accountDetails.listItem_address.label",
              "Address"
            ),
            value: address,
          },
        ]
      : []),
  ];

  return (
    <ul
      className={tw(
        "w-full",
        "px-4",
        "py-6",
        "bg-silver",
        "rounded-lg",
        "space-y-6"
      )}
    >
      {listItems.map(({ icon, label, value }, index) => (
        <li key={index} className={tw("flex", "space-x-4")}>
          <Icon
            icon={icon}
            isFilled
            size={16}
            color="text-deepBlue-500"
            margin="mt-1"
          />

          <div className={tw("flex-1")}>
            <h3 className={tw("font-semibold", "text-deepBlue-900")}>
              {label}
            </h3>
            <div className={tw("text-sm", "text-deepBlue-700")}>{value}</div>
          </div>
        </li>
      ))}
    </ul>
  );
};
