import { gql } from "@apollo/client";

import { PaymentMethod } from "graphql/types";

import { ADDRESS_FRAGMENT, AddressFragment } from "./address";

export const PAYMENT_METHOD_FRAGMENT = gql`
  fragment PaymentMethodFragment on PaymentMethod {
    id
    type
    default
    pending
    email
    entityType
    name
    address {
      ...AddressFragment
    }
    cardBrand
    cardExpMonth
    cardExpYear
    cardLast4
    orderReference
    organizationNumber
    contactName
  }
  ${ADDRESS_FRAGMENT}
`;

export interface PaymentMethodFragment
  extends Pick<
    PaymentMethod,
    | "id"
    | "type"
    | "default"
    | "pending"
    | "email"
    | "entityType"
    | "name"
    | "address"
    | "cardBrand"
    | "cardExpMonth"
    | "cardExpYear"
    | "cardLast4"
    | "orderReference"
    | "organizationNumber"
    | "contactName"
  > {
  address: AddressFragment;
}
