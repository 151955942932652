/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import {
  Base64AttachmentAttributes,
  CheckFileDocumentTypeEnum,
} from "graphql/types";

const PREVALIDATE_CRC = gql`
  mutation ($id: ID!, $file: Base64AttachmentAttributes!) {
    prevalidateCheckFile(input: { id: $id, file: $file }) {
      checkFileDocumentType
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  checkFileDocumentType?: CheckFileDocumentTypeEnum | null;
  errors: ErrorFragment[];
}

interface PrevalidateCheckFileMutation {
  prevalidateCheckFile: Payload | null;
}

export const usePrevalidateCheckFileMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    PrevalidateCheckFileMutation,
    {
      id: string;
      file: Base64AttachmentAttributes;
    }
  >(PREVALIDATE_CRC);

  const prevalidateCheckFile = (
    variables: {
      id: string;
      file: Base64AttachmentAttributes;
    },
    onSuccess?: (checkFileDocumentType: CheckFileDocumentTypeEnum) => void,
    onDataError?: (errors: ErrorFragment[]) => void
  ) =>
    mutation({
      variables,
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.prevalidateCheckFile?.errors ?? [];
        const checkFileDocumentType =
          data?.prevalidateCheckFile?.checkFileDocumentType;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess &&
            checkFileDocumentType &&
            onSuccess(checkFileDocumentType),
        });

        if (onDataError && dataErrors.length) onDataError(dataErrors);
      })
      .catch((_error: ApolloError) => null);

  return {
    prevalidateCheckFile,
    isLoading: loading,
    checkFileDocumentType: data?.prevalidateCheckFile?.checkFileDocumentType,
    errors: data?.prevalidateCheckFile?.errors ?? [],
  };
};
