import { PaymentMethodFragment } from "graphql/fragments";
import { PaymentMethodTypeEnum } from "graphql/types";
import { useTranslation } from "translations";
import { StripeCardBrand } from "utils";
import { tw } from "utils/tw";

import { PaymentMethodIcon } from "../basic";

interface Props {
  paymentMethod: PaymentMethodFragment;
}

export default ({ paymentMethod }: Props) => {
  const { t } = useTranslation("common");

  const isCreditCard = paymentMethod.type === PaymentMethodTypeEnum.StripeCard;

  const methodLine1 = paymentMethod.pending
    ? t("cardInfo.pending.line1", "Pending")
    : isCreditCard
      ? `•••• •••• •••• ${paymentMethod.cardLast4}`
      : paymentMethod.name;
  const methodLine2 = paymentMethod.pending
    ? t("cardInfo.pending.line2", "Processing details...")
    : isCreditCard
      ? t("cardInfo.expiration", "Expires {{ month }} / {{ year }}", {
          month: `0${paymentMethod.cardExpMonth}`.slice(-2),
          year: `${paymentMethod.cardExpYear}`.slice(-2),
        })
      : paymentMethod.organizationNumber;

  return (
    <div className={tw("bg-silver", "rounded-lg", "p-4", "flex", "space-x-4")}>
      <div
        className={tw(
          "h-14",
          "w-14",
          "inline-flex",
          "bg-white",
          "rounded-full",
          "items-center",
          "justify-center"
        )}
      >
        <PaymentMethodIcon
          type={
            isCreditCard
              ? (paymentMethod.cardBrand as StripeCardBrand)
              : "invoice"
          }
        />
      </div>

      <div>
        <p className={tw("text-deepBlue-900", "font-semibold")}>
          {methodLine1}
        </p>

        <p className={tw("text-deepBlue-700")}>{methodLine2}</p>
      </div>
    </div>
  );
};
