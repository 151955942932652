import { useContext, useState } from "react";

import { PopupVariant, PopupWrapper } from "components/common/popups";
import { TierFragment } from "graphql/fragments";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import PaymentDetails from "./PaymentDetails";
import Progress from "./Progress";
import SuccessfulUpdate from "./SuccessfulUpdate";
import TierSelection from "./TierSelection";

interface Props {
  // Popup props
  hasPrevious: boolean;
  variant: PopupVariant;
  onSuccess?: () => void;
}

export default (props: Props): JSX.Element | null => {
  const [selectedTier, setSelectedTier] = useState<TierFragment>();

  const { session } = useContext(AuthContext);
  const { t } = useTranslation("common");

  if (!session?.account.subscription) return null;
  const currentSubscription = session.account.subscription;

  const currentTier =
    currentSubscription.downgradeTier ?? currentSubscription.tier;
  const isIntervalChange = selectedTier?.interval !== currentTier.interval;
  const isUpgrade = (selectedTier?.amount ?? 0) > currentTier.amount;
  const isSuccess = selectedTier?.id === currentTier.id;

  return (
    <PopupWrapper
      {...props}
      popupSize="lg"
      nameSpace="login" // Needed for Tiers and IntervalToggle used in TierSelection
      title={t("popup.updateSubscription.title", "Modify your plan")}
    >
      <Progress currentStep={isSuccess ? 3 : selectedTier ? 2 : 1} />

      {isSuccess ? (
        <div className={tw("mt-8")}>
          <SuccessfulUpdate email={session.account.email} />
        </div>
      ) : selectedTier ? (
        <div className={tw("mt-8")}>
          <PaymentDetails
            account={session.account}
            tier={selectedTier}
            isUpgrade={isIntervalChange || isUpgrade}
          />
        </div>
      ) : (
        <TierSelection currentTier={currentTier} onSelect={setSelectedTier} />
      )}
    </PopupWrapper>
  );
};
