/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { TIER_FRAGMENT, TierFragment } from "graphql/fragments";
import { QueryNodeArgs } from "graphql/types";

export const TIER = gql`
  query ($id: String!) {
    tier(id: $id) {
      ...TierFragment
    }
  }
  ${TIER_FRAGMENT}
`;

export type TierQuery = { tier: TierFragment };

export const useTierQuery = (id: string) => {
  const { data, loading } = useQuery<TierQuery>(TIER, {
    variables: { id },
  });

  return { tier: data?.tier, isLoading: loading };
};

export const useTierLazyQuery = () => {
  const [getTierQuery, { data, loading, called }] = useLazyQuery<
    TierQuery,
    QueryNodeArgs
  >(TIER);

  const resolveRef = useRef<(tier?: TierFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.tier);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getTier = async (id: string) => {
    getTierQuery({ variables: { id } });

    return new Promise<TierFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getTier,
    tier: data?.tier,
    isLoading: loading,
  };
};
