import { useContext } from "react";

import { Banner } from "components/common/composite";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

interface Props {
  status?: "expired" | "failed";
}

export default ({ status }: Props): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { openPopup } = useContext(PopupContext);

  // Create a new payment method and set as default (unable to update card details of existing method)
  const updatePaymentMethodAction = {
    id: "accountSettings_paymentMethods-add_payment_method" as const,
    label: t(
      "accountSettings_org.paymentMethods.statusBanner.update",
      "Update"
    ),
    onClick: () =>
      openPopup({
        id: "AddPaymentMethod",
        props: { hasPaymentMethods: false },
      }),
  };

  switch (status) {
    case "expired":
      return (
        <Banner
          variant="warning"
          body={t(
            "accountSettings_org.paymentMethods.statusBanner_expired.body",
            "Your payment method has expired, please update it to continue using Manymore"
          )}
          actions={[updatePaymentMethodAction]}
        />
      );

    case "failed":
      return (
        <Banner
          variant="danger"
          body={t(
            "accountSettings_org.paymentMethods.statusBanner_failed.body",
            "Your payment method has failed, please update it to continue using Manymore"
          )}
          actions={[updatePaymentMethodAction]}
        />
      );

    default:
      return null;
  }
};
