import { useContext } from "react";
import { useForm } from "react-hook-form";
import { HiLockOpen } from "react-icons/hi";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import Request from "components/checkmore/composite/RequestSection";
import { CheckBox, Textarea } from "components/common/basic";
import { AttachmentUpload, Banner } from "components/common/composite";
import { PopupVariant, PopupWrapper } from "components/common/popups";
import { CheckListFragment } from "graphql/fragments";
import { useoverrideCheckMutation } from "graphql/mutations";
import { Base64AttachmentAttributes, CheckTypeEnum } from "graphql/types";
import { useErrorLogger } from "hooks";
import { useArray } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import FileUploadIllustration from "assets/illustrations/dragAndDrop/bankStatement.svg";

interface FormValues {
  note: string;
  confirmation: boolean;
}

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
  checkList: CheckListFragment;
}

export default ({ checkList, ...props }: Props) => {
  const {
    items: files,
    addOne,
    removeByIndex,
  } = useArray<Base64AttachmentAttributes>({
    items: [],
  });

  const { overrideCheck } = useoverrideCheckMutation();

  const { t } = useTranslation("checkmore");
  const { closeOnePopup } = useContext(PopupContext);

  const errorMessages = {
    note: {
      length: t(
        "checkResultpopup.form.note.error.minLength",
        "The note must be at least 30 characters"
      ),
      required: t(
        "checkResultpopup.form.note.error.required",
        "A note is required"
      ),
    },
    confirmation: t(
      "checkResultpopup.form.confirmation.error.required",
      "Please confirm that you wish to override the check"
    ),
  };

  const validationSchema = Yup.object({
    note: Yup.string()
      .required(errorMessages.note.required)
      .min(30, errorMessages.note.length),
    confirmation: Yup.boolean()
      .required(errorMessages.confirmation)
      .oneOf([true], errorMessages.confirmation),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const isConfirmed = watch("confirmation");

  const onSubmit = (values: FormValues) => {
    const CRC = checkList.checks.find(
      ({ type }) => type === CheckTypeEnum.CriminalRecord
    );
    if (!CRC) return;

    const attributes = { files, note: values.note };

    overrideCheck({ id: CRC.id, attributes, onSuccess: closeOnePopup });
  };

  const formId = "overrideCRCForm";

  return (
    <PopupWrapper
      {...props}
      nameSpace="checkmore"
      title={t("overrideCRCPopup.title", "Override Criminal Record Check")}
      footerActions={[
        {
          id: "override_crc_popup-submit",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("overrideCRCPopup.submit", "Approve and Save"),
          disabled: !isConfirmed,
        },
      ]}
    >
      <div
        className={tw(
          "w-full",
          "rounded-lg",
          "border",
          "border-silver",
          "overflow-hidden",
          "flex",
          "flex-col"
        )}
      >
        <Request checkList={checkList} />

        <form
          id={formId}
          onSubmit={handleSubmit(onSubmit)}
          className={tw("p-4", "space-y-4")}
        >
          <div className={tw("flex", "items-center", "space-x-2")}>
            <HiLockOpen size={20} />

            <h2 className={tw("text-lg", "font-bold")}>
              {t("overrideCRCPopup.form.heading", "Override")}
            </h2>
          </div>

          <Banner
            title={t(
              "overrideCRCPopup.form.note.heading",
              "Please provide your reason for overriding this check"
            )}
            body={
              <>
                <p className={tw("text-deepBlue-900", "text-sm")}>
                  {t(
                    "overrideCRCPopup.form.note.body",
                    "Please be aware that you are responsible for ensuring that the candidate has a cleared police certificate with the correct purpose."
                  )}
                </p>

                <Textarea
                  id="note"
                  label={t("overrideCRCPopup.form.note.label", "Note")}
                  hideLabel
                  placeholder={t(
                    "overrideCRCPopup.form.note.placeholder",
                    "Add a comment"
                  )}
                  {...register("note")}
                  errorMessage={errors.note?.message}
                  rows={3}
                />
              </>
            }
          />

          <div>
            <h3 className={tw("text-lg", "font-bold")}>
              {t(
                "overrideCRCPopup.form.files.heading",
                "(Optional) Upload a copy of the criminal record certificate for this candidate"
              )}
            </h3>

            <AttachmentUpload
              files={files}
              createFile={({ file }) => addOne(file)}
              destroyFile={(id) => removeByIndex(id as number)}
              illustration={{
                src: FileUploadIllustration,
                alt: t(
                  "overrideCRCPopup.form.files.illustration.alt",
                  "File attachment"
                ),
              }}
              fileRequirementsText={t(
                "overrideCRCPopup.form.files.validFileTypes",
                "PDF, PNG, JPG up to 10MB"
              )}
              validFileTypes={[
                "application/pdf",
                "image/png",
                "image/jpg",
                "image/jpeg",
              ]}
              allowMultiple
            />
          </div>

          <CheckBox
            id="confirmation"
            label={t(
              "overrideCRCPopup.form.confirmation.label",
              "I confirm that I wish to override the status of this Criminal Record Check."
            )}
            {...register("confirmation")}
          />
        </form>
      </div>
    </PopupWrapper>
  );
};
