import { IconBaseProps } from "react-icons";
import * as FaIcons from "react-icons/fa6";
import * as HiIcons from "react-icons/hi";

import { TMargin, TTextColor } from "tw-generated";
import { tw } from "utils/tw";

import * as MMIcons from "./icons";

type HiIcon = keyof typeof HiIcons;
type MMIcon = keyof typeof MMIcons;
type FaIcon = keyof typeof FaIcons;
export type IconType = HiIcon | MMIcon | FaIcon;

interface Props extends Omit<IconBaseProps, "className"> {
  icon: IconType;
  isFilled?: boolean;
  color?: TTextColor;
  margin?: TMargin;
}

export default ({
  icon,
  isFilled = false,
  strokeWidth = isFilled ? 0 : 1.5,
  color,
  margin,
  ...iconProps
}: Props): JSX.Element | null => {
  const isHeroIcon = Object.prototype.hasOwnProperty.call(HiIcons, icon);
  const isFaIcon = Object.prototype.hasOwnProperty.call(FaIcons, icon);
  const Icon = isHeroIcon
    ? HiIcons[icon as HiIcon]
    : isFaIcon
      ? FaIcons[icon as FaIcon]
      : MMIcons[icon as MMIcon];

  const iconStyles = tw(
    "shrink-0",
    "transition-all",
    "duration-300",
    color,
    margin
  );

  return (
    <Icon
      {...iconProps}
      strokeWidth={strokeWidth}
      className={`manymoreIcon ${iconStyles}`}
    />
  );
};
