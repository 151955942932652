import { PopupWrapper } from "components/common/popups";
import { AccountFragment, PaymentMethodFragment } from "graphql/fragments";
import { PaymentMethodTypeEnum } from "graphql/types";
import { useTranslation } from "translations";

import EditCardMethod from "./EditCardMethod";
import EditInvoiceMethod from "./EditInvoiceMethod";

interface Props {
  paymentMethod: PaymentMethodFragment;
  hasPrevious: boolean;
  onSuccess?: (account: AccountFragment) => void;
}

export default ({ paymentMethod, onSuccess, ...props }: Props): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <PopupWrapper
      {...props}
      title={t("popup.editPaymentMethod.title", "Edit payment method")}
      variant="slideOver"
    >
      {paymentMethod.type === PaymentMethodTypeEnum.StripeCard ? (
        <EditCardMethod paymentMethod={paymentMethod} />
      ) : (
        <EditInvoiceMethod paymentMethod={paymentMethod} />
      )}
    </PopupWrapper>
  );
};
