import { gql } from "@apollo/client";

import { Name } from "graphql/types";

export const NAME_FRAGMENT = gql`
  fragment NameFragment on Name {
    first
    last
    full
  }
`;

export interface NameFragment extends Pick<Name, "first" | "last" | "full"> {}
