import { useContext } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useCancelSubscriptionMutation } from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { tw } from "utils/tw";

import { CheckBox, Link } from "../basic";
import { Banner } from "../composite";
import { PopupVariant, PopupWrapper } from "./PopupWrapper";

interface FormValues {
  consent: boolean;
}

interface Props {
  // Popup props
  hasPrevious: boolean;
  variant: PopupVariant;
  onSuccess?: () => void;
}

export default (props: Props): JSX.Element => {
  const { cancelSubscription } = useCancelSubscriptionMutation();
  const { closeAllPopups, closeOnePopup } = useContext(PopupContext);
  const { t } = useTranslation("account");

  const validationSchema = Yup.object<FormValues>().shape({
    consent: Yup.boolean()
      .oneOf(
        [true],
        t(
          "popup.cancelSubscription.acceptTerms.error.required",
          "Please read and consent to the terms and conditions"
        )
      )
      .required(),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = () => {
    cancelSubscription();

    closeAllPopups();
  };

  const formId = "cancelSubscriptionForm";

  return (
    <PopupWrapper
      {...props}
      nameSpace="account"
      title={t(
        "popup.cancelSubscription.title",
        "Are you sure you want to cancel your subsciption?"
      )}
      leadingIcon="HiExclamation"
      leadingIconIsFilled
      leadingIconColor="text-error"
      footerActions={[
        {
          id: "subscription_cancel_popup-cancel",
          onClick: closeOnePopup,
          variant: "tertiary",
          label: t("popup.cancelSubscription.cancel", "Cancel"),
        },
        {
          id: "subscription_cancel_popup-confirm",
          type: "submit",
          form: formId,
          variant: "danger",
          label: t("popup.cancelSubscription.confirm", "Confirm cancellation"),
        },
      ]}
    >
      <div className={tw("space-y-8")}>
        <Banner
          variant="warning"
          body={t(
            "popup.cancelSubscription.banner",
            "You can keep your account until your subscription ends."
          )}
        />

        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <CheckBox
            id="consent"
            {...register("consent")}
            label={
              <Trans
                ns="account"
                i18nKey="popup.cancelSubscription.acceptTerms"
                defaults="I accept the <0>terms and conditions</0>"
                components={[
                  <Link
                    id="subscription_cancel_popup-go_to_terms"
                    to={t(
                      "common:links.terms",
                      "https://www.manymore.com/en/terms-of-use"
                    )}
                    isExternal
                  />,
                ]}
              />
            }
            errorMessage={errors.consent?.message}
          />
        </form>
      </div>
    </PopupWrapper>
  );
};
