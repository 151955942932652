import { useContext } from "react";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";

import { Table } from "components/common/composite";
import {
  DepartmentFragment,
  DepartmentMembershipFragment,
} from "graphql/fragments";
import { useDestroyDepartmentMutation } from "graphql/mutations";
import { useMembersQuery } from "graphql/queries";
import { DepartmentRoleEnum, TeamRoleEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { getNameFromAccount } from "utils";
import { tw } from "utils/tw";

import DepartmentItem from "../DepartmentItem";

interface Props {
  departments: DepartmentFragment[];
  footer?: JSX.Element;
}

export default ({ departments, footer }: Props): JSX.Element | null => {
  const { destroyDepartment } = useDestroyDepartmentMutation();
  const { members } = useMembersQuery({ page: 1, perPage: 10_000 });

  const { t } = useTranslation("account");
  const { session } = useContext(AuthContext);
  const { openPopup } = useContext(PopupContext);

  if (!session) return null;

  const columns = [
    {
      title: t(
        "accountSettings_org.team.departments.table.heading.department",
        "Department name"
      ),
      width: "w-48" as const,
      cellID: "department",
      showTotal: false,
    },
    {
      title: t(
        "accountSettings_org.team.departments.table.heading.description",
        "Description"
      ),
      width: "w-48" as const,
      cellID: "description",
      showTotal: false,
    },
    {
      title: t(
        "accountSettings_org.team.departments.table.heading.access",
        "Access"
      ),
      width: "w-48" as const,
      cellID: "access",
      showTotal: false,
    },
  ];

  const rows = departments.map((department) => {
    const actions = [
      ...(department.canManage
        ? [
            {
              id: "departmentsTable-edit_department" as const,
              Icon: HiOutlinePencil,
              label: t(
                "accountSettings_org.team.departments.table.action.edit.label",
                "Edit department"
              ),
              onClick: () =>
                openPopup({ id: "Department", props: { department } }),
            },
          ]
        : []),
      ...(department.default || !department.canManage
        ? []
        : [
            {
              id: "departmentsTable-delete_department" as const,
              Icon: HiOutlineTrash,
              label: t(
                "accountSettings_org.team.departments.table.action.delete.label",
                "Delete department"
              ),
              onClick: () =>
                openPopup({
                  id: "ConfirmAction",
                  props: {
                    onConfirmation: () => destroyDepartment(department.id),
                    confirmationLabel: t(
                      "accountSettings_org.team.departments.deleteConfirmation.label",
                      "Delete"
                    ),
                    confirmationHeading: t(
                      "accountSettings_org.team.departments.deleteConfirmation.heading",
                      "Are you sure you want to delete this department?"
                    ),
                    confirmationBody: t(
                      "accountSettings_org.team.departments.deleteConfirmation.body",
                      "This action is not reversible"
                    ),
                  },
                }),
              isDestructive: true,
            },
          ]),
    ];

    interface Membership extends Omit<DepartmentMembershipFragment, "role"> {
      role: DepartmentRoleEnum | TeamRoleEnum;
    }
    const departmentItemFromMembership = ({
      id,
      role,
      account,
      criminalRecordAccess,
      identityAccess,
    }: Membership) => {
      const memberName = getNameFromAccount(account);

      return (
        <DepartmentItem
          key={id}
          id={id}
          name={memberName ?? ""}
          crc={criminalRecordAccess}
          identity={identityAccess}
          role={role}
        />
      );
    };

    const membersAsMemberships = members
      .filter(
        (member) =>
          member.role &&
          [TeamRoleEnum.Owner, TeamRoleEnum.Admin].includes(member.role)
      )
      .sort((member) => (member.role === TeamRoleEnum.Owner ? -1 : 0))
      .map((member) => ({
        id: member.id,
        role: member.role ? member.role : TeamRoleEnum.Admin,
        account: member,
        criminalRecordAccess: true,
        identityAccess: true,
        department: { id: department.id, name: department.name },
      }));
    const sortedMemberships = [...department.departmentMemberships].sort(
      (membership) => (membership.role === DepartmentRoleEnum.Manager ? -1 : 0)
    );

    const cells = {
      department: (
        <p
          className={tw(
            "self-start",
            "text-gray-900",
            "break-words",
            "max-w-full"
          )}
        >
          {department.name}
        </p>
      ),
      description: (
        <p
          className={tw(
            "self-start",
            "text-gray-900",
            "break-words",
            "max-w-full"
          )}
        >
          {department.description}
        </p>
      ),
      access: (
        <ul className={tw("self-start", "w-full", "space-y-1")}>
          {[...membersAsMemberships, ...sortedMemberships].map(
            departmentItemFromMembership
          )}
        </ul>
      ),
    };

    return {
      id: department.id,
      cells,
      actions,
    };
  });

  return (
    <Table
      columns={columns}
      rows={rows}
      footer={footer}
      actionsLabel={t(
        "accountSettings_org.team.departments.table.actions.label",
        "Actions"
      )}
    />
  );
};
