import { useContext } from "react";
import { HiDownload, HiOutlineTrash } from "react-icons/hi";
import { Document as PDFDocument, Page as PDFPage } from "react-pdf";

import { Button, Link } from "components/common/basic";
import { AttachmentFragment } from "graphql/fragments";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import { tw } from "utils/tw";

import fileUploadIllustration from "assets/illustrations/emptyState/invoice.svg";

interface Props {
  file: AttachmentFragment;
  filename: string;
  destroyFile?: () => void;
  onClick?: () => void;
  isActive?: boolean;
  skipConfirmation?: boolean;
}

export default ({
  file,
  filename,
  destroyFile,
  onClick,
  isActive,
  skipConfirmation,
}: Props): JSX.Element => {
  const { t } = useTranslation("common");
  const { openPopup } = useContext(PopupContext);

  const deleteFile = () => {
    if (!destroyFile) return;

    if (skipConfirmation) destroyFile();
    else
      openPopup({
        id: "ConfirmAction",
        props: {
          onConfirmation: destroyFile,
          confirmationLabel: t("attachment.deleteConfirmation.label", "Delete"),
          confirmationHeading: t(
            "attachment.deleteConfirmation.heading",
            "Are you sure you want to delete this attachment?"
          ),
          confirmationBody: t(
            "attachment.deleteConfirmation.body",
            "This action is not reversible"
          ),
        },
      });
  };

  return (
    <div className={tw("flex", "flex-col", "space-y-4")}>
      <ClickWrapper
        onClick={onClick}
        className={tw(
          "p-3",
          "flex",
          "flex-col",
          "space-y-2",
          "bg-white",
          "shadow",
          "rounded-lg",
          "border-2",
          isActive ? "border-blue-900" : "border-silver"
        )}
      >
        <>
          <div
            className={tw(
              "flex",
              "justify-between",
              "space-x-4",
              "sm:space-x-11"
            )}
          >
            {!file.thumbPath && file.contentType === "application/pdf" ? (
              <div className={tw("h-20", "w-20", "overflow-hidden")}>
                <PDFDocument
                  file={process.env.REACT_APP_API_URL + file.downloadPath}
                >
                  <PDFPage
                    pageNumber={1}
                    width={80}
                    height={80}
                    className="pdfPage"
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                </PDFDocument>
              </div>
            ) : (
              <img
                width="80px"
                height="80px"
                className={tw("h-20", "w-20", "object-contain", "object-left")}
                src={
                  file.thumbPath
                    ? process.env.REACT_APP_API_URL + file.thumbPath
                    : fileUploadIllustration
                }
                alt={filename}
              />
            )}

            {Boolean(destroyFile) && (
              <button type="button" className={tw("p-3")} onClick={deleteFile}>
                <HiOutlineTrash size={20} />
              </button>
            )}
          </div>

          <p className={tw("text-xs", "w-32", "sm:w-40", "truncate")}>
            {capitalizeFirstLetter(filename)}
          </p>
        </>
      </ClickWrapper>

      <Link
        id="attachment-download_document"
        isExternal
        to={process.env.REACT_APP_API_URL + file.downloadPath}
        unstyled
      >
        <Button
          id="attachment-download_document"
          variant="tertiary"
          size="sm"
          fullWidth
          LeadingIcon={HiDownload}
        >
          {t("attachment.download.label", "Download doc.")}
        </Button>
      </Link>
    </div>
  );
};

interface ClickWrapperProps {
  children: JSX.Element;
  className: string;
  onClick?: () => void;
}

const ClickWrapper = ({ children, ...props }: ClickWrapperProps) => {
  if (props.onClick) return <button {...props}>{children}</button>;

  return <div {...props}>{children}</div>;
};
