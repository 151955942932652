import {
  Control,
  FieldError,
  FieldErrors,
  Merge,
  UseFormRegister,
} from "react-hook-form";

import { Spinner } from "components/common/basic";
import { InputTable } from "components/common/composite";
import { useDepartmentsQuery } from "graphql/queries";
import { useTranslation } from "translations";

interface Person {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  // CRC fields
  role?: string;
  type?: "permanent" | "temporary";
  departments: string;
  employmentDate?: string;
  employed?: "yes" | "no";
  phone?: string;
}

export interface FormWithPeopleInputTable {
  people?: Person[];
}

interface Props {
  control: Control<FormWithPeopleInputTable>;
  register: UseFormRegister<FormWithPeopleInputTable>;
  clearErrors: () => void;
  peopleErrors?: Merge<FieldError, FieldErrors<Person[]>>;
  isCRC?: boolean;
}

export default ({
  control,
  register,
  clearErrors,
  peopleErrors,
  isCRC,
}: Props): JSX.Element => {
  const { departments, isLoading } = useDepartmentsQuery();

  const { t } = useTranslation("common");

  if (isLoading) return <Spinner />;

  return (
    <InputTable
      name="people"
      control={control}
      register={register}
      errors={peopleErrors}
      clearErrors={clearErrors}
      minItems={1}
      defaultItem={
        isCRC
          ? {
              type: "temporary",
              employmentDate: new Date().toISOString().split("T")[0],
            }
          : {}
      }
      columns={[
        {
          key: "firstName",
          label: t("popup.addPeople.form.firstName.label", "First name"),
          width: "w-44",
          input: {
            type: "text",
            placeholder: t(
              "popup.addPeople.form.firstName.placeholder",
              "Add first name"
            ),
          },
        },
        {
          key: "lastName",
          label: t("popup.addPeople.form.lastName.label", "Last name"),
          width: "w-44",
          input: {
            type: "text",
            placeholder: t(
              "popup.addPeople.form.lastName.placeholder",
              "Add last name"
            ),
          },
        },
        {
          key: "email",
          label: t("popup.addPeople.form.email.label", "Email"),
          width: "w-44",
          input: {
            type: "email",
            placeholder: t(
              "popup.addPeople.form.email.placeholder",
              "Add email"
            ),
          },
        },
        {
          key: "phone",
          label: t("popup.addPeople.form.phone.label", "Mobile (optional)"),
          width: "w-44" as const,
          input: {
            type: "phone" as const,
            placeholder: t(
              "popup.addPeople.form.phone.placeholder",
              "Add mobile"
            ),
          },
        },
        {
          key: "departments",
          label: t("popup.addPeople.form.departments.label", "Departments"),
          width: "w-40" as const,
          input: {
            type: "select" as const,
            multiple: false,
            options: [
              {
                label: t(
                  "popup.addPeople.form.departments.option.none.label",
                  "Select department"
                ),
                value: "",
                key: "NONE",
                isDisabled: true,
              },
              ...departments
                .filter(({ canAccess }) => canAccess)
                .map(({ name, id }) => ({
                  label: name,
                  value: id,
                  key: id,
                })),
            ],
          },
        },
        ...(isCRC
          ? [
              {
                key: "role",
                label: t("popup.addPeople.form.role.label", "Role / position"),
                width: "w-40" as const,
                input: {
                  type: "text" as const,
                  placeholder: t(
                    "popup.addPeople.form.role.placeholder",
                    "Add position"
                  ),
                },
              },
              {
                key: "type",
                label: t("popup.addPeople.form.type.label", "Job type"),
                width: "w-40" as const,
                input: {
                  type: "select" as const,
                  multiple: false,
                  options: [
                    {
                      value: "permanent",
                      label: t(
                        "popup.addPeople.form.type.option.permanent",
                        "Permanent"
                      ),
                    },
                    {
                      value: "temporary",
                      label: t(
                        "popup.addPeople.form.type.option.temporary",
                        "Temporary"
                      ),
                    },
                  ],
                },
              },
              {
                key: "employmentDate",
                label: t(
                  "popup.addPeople.form.employmentDate.label",
                  "Employment date"
                ),
                width: "w-44" as const,
                input: {
                  type: "date" as const,
                },
              },
              {
                key: "employed",
                label: t("popup.addPeople.form.employed.label", "Employed"),
                width: "w-48" as const,
                input: {
                  type: "select" as const,
                  multiple: false,
                  options: [
                    {
                      value: "yes",
                      label: t(
                        "popup.addPeople.form.employed.option.yes",
                        "Yes"
                      ),
                    },
                    {
                      value: "no",
                      label: t("popup.addPeople.form.employed.option.no", "No"),
                    },
                  ],
                },
              },
            ]
          : []),
      ]}
      itemLabel={t("popup.addPeople.form.itemLabel", "Add a person")}
      addItemLabel={t("popup.addPeople.form.button.addPerson", "Add person")}
      removeItemLabel={t(
        "popup.addPeople.form.button.removePerson",
        "Remove person"
      )}
    />
  );
};
