import { useState } from "react";

import { Divider, Icon } from "components/common/basic";
import { PriceCurrencyEnum } from "graphql/types";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  price: string;
  currency: PriceCurrencyEnum;
  labels: {
    recipients: string;
    freeRecipients: string;
    items: string;
  };
  recipients: number;
  freeRecipients: number;
  paidTotal: string;
  freeTotal: string;
  total: string;
}

export default ({
  price,
  currency,
  labels,
  recipients,
  freeRecipients,
  paidTotal,
  freeTotal,
  total,
}: Props): JSX.Element | null => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { t } = useTranslation("common");

  return (
    <div
      className={tw(
        "p-6",
        isExpanded ? "space-y-4" : "space-y-2",
        "rounded-lg",
        "border",
        "border-deepBlue-100"
      )}
    >
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className={tw("flex", "items-center", "space-x-2")}
      >
        <span className={tw("text-sm", "font-bold", "text-deepBlue-700")}>
          {t("priceSummary.expandSummary", "View summary")}
        </span>

        <Icon
          icon={isExpanded ? "HiChevronUp" : "HiChevronDown"}
          isFilled
          size={16}
        />
      </button>

      {isExpanded && (
        <>
          <Divider />

          <div className={tw("flex", "justify-between")}>
            <div>
              <p className={tw("font-semibold", "text-deepBlue-700")}>
                {labels.recipients}
              </p>

              <p className={tw("text-sm", "text-deepBlue-500")}>
                {`${recipients} ${labels.items} X ${price},- ${currency}`}
              </p>
            </div>

            <p className={tw("font-semibold", "text-deepBlue-900")}>
              {paidTotal},- {currency}
            </p>
          </div>

          {freeRecipients > 0 && (
            <div className={tw("flex", "justify-between")}>
              <div>
                <p className={tw("font-semibold", "text-success")}>
                  {labels.freeRecipients}
                </p>

                <p className={tw("text-sm", "text-deepBlue-500")}>
                  {`${freeRecipients} ${labels.items}`}
                </p>
              </div>

              <p
                className={tw(
                  "font-semibold",
                  "text-deepBlue-900",
                  "line-through"
                )}
              >
                {freeTotal},- {currency}
              </p>
            </div>
          )}

          <Divider />
        </>
      )}

      <div className={tw("flex", "justify-between")}>
        <p className={tw("font-semibold", "text-deepBlue-700")}>
          {t("priceSummary.total.label", "Due today")}
        </p>

        <p className={tw("text-lg", "font-bold", "text-deepBlue-900")}>
          {total},- {currency}
        </p>
      </div>
    </div>
  );
};
