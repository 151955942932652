import { useContext } from "react";

import { Button } from "components/common/basic";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import PhoneWithQuestion from "assets/illustrations/emptyState/phoneWithQuestion.svg";

export default (): JSX.Element => {
  const { t } = useTranslation("account");
  const { openPopup } = useContext(PopupContext);

  const openPaymentMethodPopup = () =>
    openPopup({
      id: "AddPaymentMethod",
      props: { hasPaymentMethods: false },
    });

  return (
    <div
      className={tw(
        "mt-10",
        "max-w-sm",
        "mx-auto",
        "text-center",
        "flex",
        "flex-col",
        "space-y-10"
      )}
    >
      <img
        className={tw("h-40", "object-contain")}
        src={PhoneWithQuestion}
        alt="A hand holding a phone showing a question mark on the screen"
      />

      <div className={tw("space-y-2")}>
        <h2 className={tw("text-lg", "font-extrabold")}>
          {t(
            "accountSettings_org.paymentMethods.empty.heading",
            "You don't have any payment methods"
          )}
        </h2>

        <p>
          {t(
            "accountSettings_org.paymentMethods.empty.paragraph",
            "Add your payment method to enjoy all benefits of subscription."
          )}
        </p>
      </div>

      <Button
        id="bank_accounts__empty_state-add_first_account"
        onClick={openPaymentMethodPopup}
      >
        {t(
          "accountSettings_org.paymentMethods.empty.addPaymentMethod",
          "Add payment method"
        )}
      </Button>
    </div>
  );
};
