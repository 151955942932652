import { gql } from "@apollo/client";

import { Product } from "graphql/types";

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    type
    amount
    currency
    limit
    usage
  }
`;

export interface ProductFragment
  extends Pick<Product, "amount" | "currency" | "limit" | "type" | "usage"> {}
