/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  PAYMENT_METHOD_FRAGMENT,
  PaymentMethodFragment,
} from "graphql/fragments";
import { PAYMENT_METHODS, PaymentMethodsQuery } from "graphql/queries";
import { StripeCardAttributes } from "graphql/types";

const CREATE_STRIPE_SETUP_INTENT = gql`
  mutation ($attributes: StripeCardAttributes!) {
    createStripeCard(input: { attributes: $attributes }) {
      paymentMethod {
        ...PaymentMethodFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${PAYMENT_METHOD_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  paymentMethod?: PaymentMethodFragment | null;
  errors: ErrorFragment[];
}

interface CreateStripeCardMutation {
  createStripeCard: Payload | null;
}

export const useCreateStripeCardMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.createdStripeCard",
    "Card added successfully"
  );
  // Values for extraction
  // t("common:success.createdStripeCard", "Card added successfully")

  const [mutation, { data, loading }] = useMutation<
    CreateStripeCardMutation,
    { attributes: StripeCardAttributes }
  >(CREATE_STRIPE_SETUP_INTENT);

  const createStripeCard = (
    attributes: StripeCardAttributes,
    onSuccess?: (paymentMethod: PaymentMethodFragment) => void
  ) =>
    mutation({
      variables: { attributes },
      update(cache, { data: newData }) {
        const newNode = newData?.createStripeCard?.paymentMethod;
        if (!newNode) return;

        cache.modify({
          fields: {
            paymentMethods() {
              const existing = cache.readQuery<PaymentMethodsQuery>({
                query: PAYMENT_METHODS,
              });
              if (!existing) return;

              const items = [
                ...(existing?.paymentMethods.items ?? []),
                newNode,
              ];

              cache.writeQuery<PaymentMethodsQuery>({
                query: PAYMENT_METHODS,
                data: {
                  paymentMethods: {
                    items,
                    total: existing.paymentMethods.total + 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createStripeCard?.errors ?? [];
        const paymentMethod = data?.createStripeCard?.paymentMethod;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && paymentMethod && onSuccess(paymentMethod),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createStripeCard,
    isLoading: loading,
    paymentMethod: data?.createStripeCard?.paymentMethod,
    errors: data?.createStripeCard?.errors ?? [],
  };
};
