import { gql } from "@apollo/client";

import { Subscription } from "graphql/types";

import { TIER_FRAGMENT, TierFragment } from "./tier";

export const SUBSCRIPTION_FRAGMENT = gql`
  fragment SubscriptionFragment on Subscription {
    tier {
      ...TierFragment
    }
    currentPeriodStartAt
    currentPeriodEndAt
    downgradedAt
    downgradeTier {
      ...TierFragment
    }
  }
  ${TIER_FRAGMENT}
`;

export interface SubscriptionFragment
  extends Pick<
    Subscription,
    "currentPeriodStartAt" | "currentPeriodEndAt" | "downgradedAt"
  > {
  tier: TierFragment;
  downgradeTier: TierFragment;
}
