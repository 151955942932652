import {
  UseTranslationOptions,
  UseTranslationResponse,
  useTranslation,
} from "react-i18next";

import { NameSpace } from "./shared";

type KPrefix = string;

export default (
  ns: NameSpace,
  options?: UseTranslationOptions<KPrefix>
): UseTranslationResponse<NameSpace, KPrefix> => useTranslation(ns, options);
