import { useContext } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Input } from "components/common/basic";
import { PopupVariant, PopupWrapper } from "components/common/popups";
import { useUpdateAccountMutation } from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

interface FormValues {
  name: string;
}

interface Props {
  name: string;
  hasPrevious: boolean;
  variant: PopupVariant;
}

export default ({ name, ...props }: Props): JSX.Element => {
  const { updateAccount } = useUpdateAccountMutation();

  const { t } = useTranslation("account");
  const { closeOnePopup } = useContext(PopupContext);

  const validationSchema = Yup.object({
    name: Yup.string().required(),
  });

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: { name },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = (attributes: FormValues) =>
    updateAccount({ attributes }, closeOnePopup);

  const formId = "accountNameForm";

  return (
    <PopupWrapper
      {...props}
      nameSpace="account"
      title={t("accountSettings.namePopup.heading", "Add your full name")}
      subTitle={t(
        "accountSettings.namePopup.description",
        "Your legal name as it appears on official documents such as your driving licence or passport."
      )}
      footerActions={[
        {
          id: "accountSettings-add_name",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("accountSettings.namePopup.button.submit", "Save"),
          disabled: !isValid,
        },
      ]}
    >
      <form onSubmit={handleSubmit(onSubmit)} id={formId}>
        <Input
          id="name"
          label={t("accountSettings.namePopup.name.label", "Full name")}
          {...register("name")}
        />
      </form>
    </PopupWrapper>
  );
};
