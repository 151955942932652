import { Helmet } from "react-helmet-async";

import { tw } from "utils/tw";

interface Props {
  title: string;
  left: JSX.Element;
  right: JSX.Element;
}
export default ({ title, left, right }: Props): JSX.Element => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>

    <div
      className={tw("flex-1", "flex", "flex-wrap-reverse", "xl:flex-nowrap")}
    >
      <div
        className={tw(
          "bg-white",
          "w-full",
          "p-6",
          "pt-10",
          "xl:p-20",
          "xl:pt-20"
        )}
      >
        <div className={tw("mx-auto", "max-w-screen-md")}>{left}</div>
      </div>

      <div
        className={tw("bg-silver", "w-full", "xl:max-w-lg", "p-6", "xl:p-10")}
      >
        {right}
      </div>
    </div>
  </>
);
