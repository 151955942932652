import { useContext } from "react";
import {
  HiOutlineAdjustments,
  HiOutlinePencilAlt,
  HiShieldCheck,
} from "react-icons/hi";

import {
  Badge,
  Button,
  Card,
  CardBody,
  Divider,
} from "components/common/basic";
import { AvatarUpload, HeadingWithNotice } from "components/common/composite";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { getNameFromSession } from "utils";
import { tw } from "utils/tw";

import {
  AccountCrumbs,
  AddressSection,
  EmailSection,
  PhoneSection,
} from "../shared";
import IdentitiesSection from "./IdentitiesSection";
import PasswordSection from "./PasswordSection";

export default (): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { session } = useContext(AuthContext);
  const { openPopup } = useContext(PopupContext);

  if (!session) return null;

  const hasVerifiedName = Boolean(session.account.defaultIdentity);

  return (
    <div className={tw("space-y-8")}>
      <div className={tw("space-y-4")}>
        <AccountCrumbs
          currentPage={t(
            "accountSettings_ind.accountInfo.breadcrumb",
            "Account info"
          )}
        />

        <HeadingWithNotice
          heading={t("accountSettings_ind.header", "Settings")}
          LeadingIcon={HiOutlineAdjustments}
          notice={{
            id: "indAccountSettings_accountInfo-headerNotice",
            heading: t("accountSettings_ind.noticeHeader", "Settings overview"),
            body: t(
              "accountSettings_ind.noticeDescription",
              "Keep your profile up to date and complete the fields below. The ideal profile picture is square in format e.g. 500x500 pixels. Read more in our help center."
            ),
          }}
        />
      </div>

      <div
        className={tw(
          "max-w-screen-lg",
          "flex",
          "flex-col",
          "space-y-8",
          "lg:flex-row",
          "lg:space-y-0",
          "lg:space-x-28"
        )}
      >
        <Card
          isFlat
          className={tw(
            "border",
            "border-deepBlue-100",
            "shrink-0",
            "w-full",
            "lg:w-72"
          )}
        >
          <CardBody>
            <h2 className={tw("text-lg", "font-bold")}>
              {t("accountSettings_ind.card.profilePicture", "Profile picture")}
            </h2>

            <AvatarUpload account={session.account} />

            <Divider />

            <div className={tw("w-full", "flex", "justify-between")}>
              <h2 className={tw("text-lg", "font-bold")}>
                {t("accountSettings_ind.card.name.header", "Name")}
              </h2>

              {!hasVerifiedName && (
                <button
                  className={tw("text-deepBlue-500", "hover:text-deepBlue-700")}
                  onClick={() =>
                    openPopup({
                      id: "AccountName",
                      props: { name: session.account.name ?? "" },
                    })
                  }
                >
                  <HiOutlinePencilAlt size={24} />
                </button>
              )}
            </div>

            <p className={tw("text-sm", "text-deepBlue-500")}>
              {t(
                "accountSettings_ind.contactDetails.name.helpText",
                "Your legal name as it appears on official documents such as your driving licence or passport."
              )}
            </p>

            <div>
              <p className={tw("text-sm", "text-deepBlue-300")}>
                {t("accountSettings_ind.card.name.fullName", "Full name:")}
              </p>
              <p className={tw("text-lg", "font-bold")}>
                {getNameFromSession(session)}
              </p>
            </div>

            {hasVerifiedName && (
              <Badge
                variant="success"
                LeadingIcon={HiShieldCheck}
                text={t(
                  "accountSettings.contactDetails.name.verified",
                  "Verified"
                )}
              />
            )}
          </CardBody>
        </Card>

        <div className={tw("flex", "flex-col", "space-y-7", "w-full")}>
          {session.account.identities && (
            <IdentitiesSection identities={session.account.identities} />
          )}

          <EmailSection
            account={session.account}
            heading={t(
              "accountSettings_ind.contactDetails.email.heading",
              "Email"
            )}
            description={t(
              "accountSettings_ind.contactDetails.email.description",
              "You can change your email at any time. You will be asked to verify your new email during the process. Once verified, it will become active."
            )}
          />

          <Divider />

          <PhoneSection
            account={session.account}
            heading={t(
              "accountSettings_ind.contactDetails.phone.heading",
              "Phone number"
            )}
            description={t(
              "accountSettings_ind.contactDetails.phone.description",
              "Add your phone number"
            )}
          />

          <Divider />

          <AddressSection
            account={session.account}
            heading={t(
              "accountSettings_ind.contactDetails.address.heading",
              "Address"
            )}
            description={t(
              "accountSettings_ind.contactDetails.address.description",
              "Add your complete address"
            )}
          />

          <Divider />

          <PasswordSection />

          <Divider />

          <div className={tw("space-y-6")}>
            <div className={tw("space-y-2")}>
              <h2 className={tw("text-lg", "font-bold")}>
                {t(
                  "accountSettings_ind.accountInfo.accountDeletion.header",
                  "Delete account"
                )}
              </h2>

              <p className={tw("text-sm", "text-deepBlue-500")}>
                {t(
                  "accountSettings_ind.accountInfo.accountDeletion.helpText",
                  "Deleting your private account means that all information will be deleted. This action can't be undone."
                )}
              </p>
            </div>

            <Button
              id="accountSettings-delete_account"
              variant="tertiary"
              size="sm"
              onClick={() => openPopup({ id: "DeleteAccount" })}
            >
              {t(
                "accountSettings_ind.accountInfo.accountDeletion.button.label",
                "Delete account"
              )}
            </Button>
          </div>

          <Divider />
        </div>
      </div>
    </div>
  );
};
