import { useContext } from "react";
import { IconType } from "react-icons";
import {
  HiOutlineCollection,
  HiOutlineCreditCard,
  HiOutlineCurrencyDollar,
  HiOutlineDocumentText,
  HiOutlineIdentification,
  HiOutlineUserGroup,
} from "react-icons/hi";

import { ActionID } from "components/common/basic";
import { AccountTagEnum, TeamRoleEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { RoutesByModule } from "utils";

interface LinkProps {
  actionId: ActionID;
  routeName: RoutesByModule["account"];
  Icon: IconType;
  label: string;
  helpText: string;
}

export default (): LinkProps[] => {
  const { session } = useContext(AuthContext);
  const { t } = useTranslation("account");

  if (!session) return [];

  const hasCheckmore = session.account.features.checks !== false;
  const hasTellmore = session.account.features.whistleblowing !== false;
  const hasOwnerAccess = session.role === TeamRoleEnum.Owner;
  const hasAdminAccess = session.role === TeamRoleEnum.Admin;

  const hasSubscriptionsAccess =
    (hasOwnerAccess || hasAdminAccess) && (hasCheckmore || hasTellmore);
  const hasPricePlanAccess =
    (hasOwnerAccess || hasAdminAccess) &&
    session.account.tag === AccountTagEnum.MarchingBand &&
    session.account.pricePlan;

  const links: LinkProps[] = [
    {
      actionId: "accountSettings-go_to_orgInfo",
      routeName: "SETTINGS_INFO",
      Icon: HiOutlineIdentification,
      label: t("accountSettings_org.link.orgInfo.label", "Org info"),
      helpText: t(
        "accountSettings_org.link.orgInfo.helpText",
        "Provide personal details and how we can reach you"
      ),
    },
    {
      actionId: "accountSettings-go_to_team",
      routeName: "SETTINGS_TEAM",
      Icon: HiOutlineUserGroup,
      label: t("accountSettings_org.link.team.label", "Team"),
      helpText: t(
        "accountSettings_org.link.team.helpText",
        "Add internal staff, create teams, set roles and locations"
      ),
    },
    ...(hasPricePlanAccess
      ? [
          {
            actionId: "accountSettings-go_to_paymentPlan" as const,
            routeName: "SETTINGS_PLAN" as const,
            Icon: HiOutlineCreditCard,
            label: t(
              "accountSettings_org.link.paymentPlan.label",
              "Payment plan"
            ),
            helpText: t(
              "accountSettings_org.link.paymentPlan.helpText",
              "Set and adjust your payment plan for your organisation."
            ),
          },
        ]
      : []),
    ...(session?.account.features.invoices !== false
      ? [
          {
            actionId: "accountSettings-go_to_paymentSettings" as const,
            routeName: "SETTINGS_PAYOUT" as const,
            Icon: HiOutlineCurrencyDollar,
            label: t(
              "accountSettings_org.link.payoutSettings.label",
              "Invoicing service"
            ),
            helpText: t(
              "accountSettings_org.link.payoutSettings.helpText",
              "Set and adjust your payment settings for your organisation."
            ),
          },
        ]
      : []),
    ...(session?.account.features.checks !== false
      ? [
          {
            actionId: "accountSettings-go_to_policyDocuments" as const,
            routeName: "SETTINGS_POLICY_DOCUMENTS" as const,
            Icon: HiOutlineDocumentText,
            label: t(
              "accountSettings_org.link.policyDocuments.label",
              "Policy documents"
            ),
            helpText: t(
              "accountSettings_org.link.policyDocuments.helpText",
              "Upload and save your organisations policy documents."
            ),
          },
        ]
      : []),
    ...(!hasPricePlanAccess && hasSubscriptionsAccess
      ? [
          {
            actionId: "accountSettings-go_to_subscriptions" as const,
            routeName: "SETTINGS_SUBSCRIPTIONS" as const,
            Icon: HiOutlineCollection,
            label: t(
              "accountSettings_org.link.subscriptions.label",
              "Subscriptions"
            ),
            helpText: t(
              "accountSettings_org.link.subscriptions.helpText",
              "Manage your subscription plans."
            ),
          },
          {
            actionId: "accountSettings-go_to_payment_methods" as const,
            routeName: "SETTINGS_PAYMENT_METHODS" as const,
            Icon: HiOutlineCreditCard,
            label: t(
              "accountSettings_org.link.paymentMethods.label",
              "Payment methods"
            ),
            helpText: t(
              "accountSettings_org.link.paymentMethods.helpText",
              "Choose how you pay for your subscriptions."
            ),
          },
        ]
      : []),
  ];

  return links;
};
