/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ACCOUNT_FRAGMENT,
  AccountFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";

const UPDATE_SUBSCRIPTION = gql`
  mutation ($tierId: String!) {
    updateSubscription(input: { tierId: $tierId }) {
      account {
        ...AccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  account?: AccountFragment | null;
  errors: ErrorFragment[];
}

interface UpdateSubscriptionMutation {
  updateSubscription: Payload | null;
}

export const useUpdateSubscriptionMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateSubscriptionMutation,
    { tierId: string }
  >(UPDATE_SUBSCRIPTION);

  const updateSubscription = (
    tierId: string,
    onSuccess?: (account: AccountFragment) => void,
    onDataError?: (errors: ErrorFragment[]) => void
  ) =>
    mutation({
      variables: { tierId },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateSubscription?.errors ?? [];
        const account = data?.updateSubscription?.account;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && account && onSuccess(account),
        });

        if (onDataError && dataErrors.length) onDataError(dataErrors);
      })
      .catch((_error: ApolloError) => null);

  return {
    updateSubscription,
    isLoading: loading,
    account: data?.updateSubscription?.account,
    errors: data?.updateSubscription?.errors ?? [],
  };
};
