import { HiChevronRight } from "react-icons/hi";

import { ActionID, Link } from "components/common/basic";
import { tw } from "utils/tw";

interface Props {
  previousPages: {
    id: ActionID;
    to: string;
    label: string;
  }[];
  currentPage: string;
}

export default ({ previousPages, currentPage }: Props): JSX.Element => {
  const crumbStyles = tw(
    "flex",
    "items-center",
    "space-x-2",
    "text-sm",
    "font-semibold"
  );

  return (
    <div className={tw("flex", "flex-wrap", "gap-y-1", "gap-x-2")}>
      {previousPages.map(({ id, to, label }) => (
        <Link
          key={id}
          id={id}
          to={to}
          unstyled
          className={tw(
            crumbStyles,
            "text-deepBlue-500",
            "hover:opacity-90",
            "md:text-gray-900"
          )}
        >
          <span>{label}</span>

          <HiChevronRight size={20} />
        </Link>
      ))}

      <div className={tw(crumbStyles, "text-deepBlue-900", "md:text-gray-900")}>
        <span>{currentPage}</span>
      </div>
    </div>
  );
};
