/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  DOCUMENT_FRAGMENT,
  DocumentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { DOCUMENTS, DocumentsQuery } from "graphql/queries";

const DESTROY_DOCUMENTS = gql`
  mutation ($ids: [ID!]!) {
    destroyDocuments(input: { ids: $ids }) {
      documents {
        ...DocumentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  documents?: DocumentFragment[] | null;
  errors: ErrorFragment[];
}

interface DestroyDocumentsMutation {
  destroyDocuments: Payload | null;
}

export const useDestroyDocumentsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.destroyedDocuments",
    "Documents destroyed successfully"
  );
  // Values for extraction
  // t("common:success.destroyedDocuments", "Documents destroyed successfully")

  const [mutation, { data, loading, called }] = useMutation<
    DestroyDocumentsMutation,
    { ids: string[] }
  >(DESTROY_DOCUMENTS);

  const destroyDocuments = (
    ids: string[],
    onSuccess?: (response: DocumentFragment[]) => void
  ) =>
    mutation({
      variables: { ids },
      update(cache) {
        cache.modify({
          fields: {
            documents() {
              const existingDocuments = cache.readQuery<DocumentsQuery>({
                query: DOCUMENTS,
              });
              if (!existingDocuments?.documents.items) return;

              const items = existingDocuments.documents.items?.filter(
                ({ id }) => !ids.includes(id)
              );

              cache.writeQuery<DocumentsQuery>({
                query: DOCUMENTS,
                data: {
                  documents: {
                    items,
                    total: existingDocuments.documents.total - ids.length,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.destroyDocuments?.errors ?? [];
        const document = data?.destroyDocuments?.documents;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => document && onSuccess && onSuccess(document),
          onRetry: () => destroyDocuments(ids, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyDocuments,
    isLoading: loading,
    called,
    documents: data?.destroyDocuments?.documents,
    errors: data?.destroyDocuments?.errors ?? [],
  };
};
