/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  PAYMENT_METHOD_FRAGMENT,
  PaymentMethodFragment,
} from "graphql/fragments";
import { PAYMENT_METHODS, PaymentMethodsQuery } from "graphql/queries";
import { CreatePaymentMethodAttributes } from "graphql/types";

const CREATE_PAYMENT_METHOD = gql`
  mutation ($attributes: CreatePaymentMethodAttributes!) {
    createPaymentMethod(input: { attributes: $attributes }) {
      paymentMethod {
        ...PaymentMethodFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${PAYMENT_METHOD_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  paymentMethod?: PaymentMethodFragment | null;
  errors: ErrorFragment[];
}

interface CreatePaymentMethodMutation {
  createPaymentMethod: Payload | null;
}

export const useCreatePaymentMethodMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.createdPaymentMethod",
    "Payment method added successfully"
  );
  // Values for extraction
  // t("common:success.createdPaymentMethod", "Payment method added successfully")

  const [mutation, { data, loading }] = useMutation<
    CreatePaymentMethodMutation,
    { attributes: CreatePaymentMethodAttributes }
  >(CREATE_PAYMENT_METHOD);

  const createPaymentMethod = (
    attributes: CreatePaymentMethodAttributes,
    onSuccess?: (paymentMethod: PaymentMethodFragment) => void
  ) =>
    mutation({
      variables: { attributes },
      update(cache, { data: newData }) {
        const newNode = newData?.createPaymentMethod?.paymentMethod;
        if (!newNode) return;

        cache.modify({
          fields: {
            paymentMethods() {
              const existing = cache.readQuery<PaymentMethodsQuery>({
                query: PAYMENT_METHODS,
              });
              if (!existing) return;

              const items = [
                ...(existing?.paymentMethods.items ?? []),
                newNode,
              ];

              cache.writeQuery<PaymentMethodsQuery>({
                query: PAYMENT_METHODS,
                data: {
                  paymentMethods: {
                    items,
                    total: existing.paymentMethods.total + 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createPaymentMethod?.errors ?? [];
        const paymentMethod = data?.createPaymentMethod?.paymentMethod;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && paymentMethod && onSuccess(paymentMethod),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createPaymentMethod,
    isLoading: loading,
    paymentMethod: data?.createPaymentMethod?.paymentMethod,
    errors: data?.createPaymentMethod?.errors ?? [],
  };
};
