import React, { useContext, useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { HiOutlineTrash, HiPlus } from "react-icons/hi";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
  Button,
  CheckBox,
  Divider,
  Input,
  Select,
  Spinner,
} from "components/common/basic";
import { Banner } from "components/common/composite";
import { AccountFragment } from "graphql/fragments";
import {
  useAddAccountMemberMutation,
  useUpdateAccountMemberMutation,
} from "graphql/mutations";
import { useDepartmentsQuery } from "graphql/queries";
import { DepartmentRoleEnum, TeamRoleEnum } from "graphql/types";
import { useErrorLogger } from "hooks";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { useRoutePaths } from "utils";
import { tw } from "utils/tw";

interface Role {
  departmentId: string;
  role: DepartmentRoleEnum;
  accessToCRC: boolean;
  accessToIdentity: boolean;
}

interface FormValues {
  firstName: string;
  lastName: string;
  systemAdmin: boolean;
  roles: Role[];
}

interface Props {
  member: Partial<AccountFragment>;
  onSuccess: () => void;
  showAlreadyExistsNotice?: boolean;
}

export default ({
  member,
  onSuccess,
  showAlreadyExistsNotice = false,
}: Props): JSX.Element => {
  const { departments } = useDepartmentsQuery();
  const { addAccountMember } = useAddAccountMemberMutation();
  const { updateAccountMember } = useUpdateAccountMemberMutation();

  const { session } = useContext(AuthContext);
  const { t } = useTranslation("common");
  const { reportErrors } = useErrorLogger();
  const getRoutePath = useRoutePaths();

  const isExisting = Boolean(member.id);
  const hasCheckmore = session?.account.features.checks !== false;

  const errorMessages = {
    firstName: t(
      "popup.teamMember.form.firstName.error.required",
      "A first name is required"
    ),
    lastName: t(
      "popup.teamMember.form.lastName.error.required",
      "A last name is required"
    ),
    department: t(
      "popup.teamMember.form.department.error.duplicate",
      "Only one role is allowed per department"
    ),
  };
  const validationSchema = Yup.object({
    firstName: Yup.string().trim().required(errorMessages.firstName),
    lastName: Yup.string().trim().required(errorMessages.lastName),
    systemAdmin: Yup.boolean().required(),
    roles: Yup.array()
      .of(
        Yup.object({
          departmentId: Yup.string()
            .required()
            .test(
              "uniqueDepartments",
              errorMessages.department,
              (value, context: Yup.TestContext) => {
                if (!value) return true;

                const contextValues = context.from?.map(({ value }) => value);
                if (!contextValues) return true;

                const roles: Role[] = contextValues[1].roles;
                const identical = roles.filter((sibling) => {
                  return sibling.departmentId === value;
                });

                return identical.length < 2;
              }
            )
            .oneOf(departments.map(({ id }) => id)),
          role: Yup.string()
            .required()
            .oneOf(Object.values(DepartmentRoleEnum)),
          accessToCRC: Yup.boolean().required(),
          accessToIdentity: Yup.boolean().required(),
        })
      )
      .required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    clearErrors,
    watch,
    trigger,
  } = useForm<FormValues>({
    defaultValues: {
      firstName: member.primaryUser
        ? (member.primaryUser.firstName ?? "-")
        : "",
      lastName: member.primaryUser ? (member.primaryUser.lastName ?? "-") : "",
      systemAdmin: member.role === TeamRoleEnum.Admin,
      roles: member.departmentMemberships?.map((membership) => ({
        departmentId: membership.department.id,
        role: membership.role,
        accessToCRC: membership.criminalRecordAccess,
        accessToIdentity: membership.identityAccess,
      })),
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  reportErrors(errors);
  const fieldValues = watch("roles") ?? [];
  const isAdmin = watch("systemAdmin");
  const isOwner = member.role === TeamRoleEnum.Owner;

  const { fields, append, remove } = useFieldArray({ control, name: "roles" });
  const selectedDepartmentIds = fieldValues.map(
    (fieldValue) => fieldValue.departmentId
  );
  const availableDepartments = departments.filter(
    (department) =>
      department.canManage && !selectedDepartmentIds.includes(department.id)
  );

  const addDefaultRole = () => {
    const defaultRole = {
      departmentId: availableDepartments[0]?.id,
      role: DepartmentRoleEnum.Member,
      accessToCRC: false,
      accessToIdentity: false,
    };

    append(defaultRole);
  };

  useEffect(() => {
    if (!departments.length) return;

    if (fields.length === 0) addDefaultRole();
  }, [departments.length]);

  const onSubmit = (values: FormValues) => {
    const role = values.systemAdmin ? TeamRoleEnum.Admin : TeamRoleEnum.Member;
    const departmentMemberships = values.roles.map((membership) => ({
      departmentId: membership.departmentId,
      role: membership.role,
      criminalRecordAccess: membership.accessToCRC,
      identityAccess: membership.accessToIdentity,
    }));

    if (member.id)
      updateAccountMember(
        {
          memberId: member.id,
          attributes: { departmentMemberships, role },
        },
        onSuccess
      );
    else
      addAccountMember(
        {
          attributes: {
            departmentMemberships,
            role,
            email: member.email ?? "",
            firstName: values.firstName,
            lastName: values.lastName,
          },
        },
        onSuccess
      );
  };

  const formId = "addMemberForm";

  const hasAdminAccess = session?.role === TeamRoleEnum.Admin;
  const hasOwnerAccess = session?.role === TeamRoleEnum.Owner;

  if (!departments.length) return <Spinner />;

  return (
    <>
      <form
        className={tw("w-full", "space-y-9", "my-8")}
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          className={tw("space-y-4", "md:flex", "md:space-y-0", "md:space-x-4")}
        >
          <Input
            id="email"
            type="email"
            label={t("popup.teamMember.form.email.label", "Email")}
            value={member.email}
            fullWidth
            disabled
          />

          <Input
            id="firstName"
            {...register("firstName")}
            label={t("popup.teamMember.form.firstName.label", "First name")}
            placeholder={t(
              "popup.teamMember.form.firstName.placeholder",
              "First name"
            )}
            errorMessage={errors.firstName?.message}
            fullWidth
            disabled={isExisting}
          />

          <Input
            id="lastName"
            {...register("lastName")}
            label={t("popup.teamMember.form.lastName.label", "Last name")}
            placeholder={t(
              "popup.teamMember.form.lastName.placeholder",
              "Last name"
            )}
            errorMessage={errors.lastName?.message}
            fullWidth
            disabled={isExisting}
          />
        </div>

        {showAlreadyExistsNotice && (
          <Banner
            title={t(
              "popup.teamMember_edit.infoCard.heading",
              "Member already exists!"
            )}
            body={t(
              "popup.teamMember_edit.infoCard.body",
              "The member you are trying to add already exists in your members list. Edit their information below"
            )}
          />
        )}

        {(hasOwnerAccess || hasAdminAccess) && !isOwner && (
          <div
            className={tw(
              "p-6",
              "w-full",
              "space-y-6",
              "border",
              "border-deepBlue-100",
              "rounded-lg"
            )}
          >
            <div className={tw("w-full", "space-y-2")}>
              <h2 className={tw("text-lg", "font-bold")}>
                {t(
                  "popup.teamMember.form.sytemAccess.heading",
                  "Grant team admin access"
                )}
              </h2>

              <p>
                {t(
                  "popup.teamMember.form.sytemAccess.helpText",
                  "By making this user a team admin, you give them full access to all departments on this account including access to sensitive documents and personal data."
                )}
              </p>
            </div>

            <CheckBox
              id="systemAdmin"
              {...register("systemAdmin")}
              label={t(
                "popup.teamMember.form.systemAdmin.label",
                "Make this user a system admin"
              )}
            />
          </div>
        )}

        {isOwner ? (
          <Banner
            title={t(
              "popup.teamMember.form.owner.infoCard.heading",
              "Member's access"
            )}
            body={t(
              "popup.teamMember.form.owner.infoCard.body",
              "This member is the owner of this team account and they have all access rights."
            )}
            actions={[
              {
                id: "member_popup-go_to_roles",
                label: t(
                  "popup.teamMember.form.owner.infoCard.link.label",
                  "View the roles page for more information"
                ),
                to: getRoutePath({
                  module: "account",
                  routeName: "SETTINGS_TEAM_ROLES",
                }),
                onClick: onSuccess,
              },
            ]}
          />
        ) : (
          <div
            className={tw(
              "p-6",
              "w-full",
              "space-y-6",
              "border",
              "border-deepBlue-100",
              "rounded-lg"
            )}
          >
            <h2 className={tw("text-lg", "font-bold")}>
              {t("popup.teamMember.form.roles.heading", "Add roles and access")}
            </h2>

            {!(hasAdminAccess || hasOwnerAccess) && (
              <Banner
                title={t(
                  "popup.teamMember.roles.infoCard_notSystemAdmin.body",
                  "If you need to give a member Criminal record certificate or national identity number access please contact your team admin or owner"
                )}
              />
            )}

            {isAdmin ? (
              <p className={tw("whitespace-pre-wrap")}>
                {t(
                  "popup.teamMember.form.roles_admin.helpText",
                  "Team admins have access to all departments, sensitive documents, and personal data.\nDepartment level roles are not necessary to configure."
                )}
              </p>
            ) : (
              fields.map((roleField, index) => {
                const removeItem = () => {
                  remove(index);

                  clearErrors();
                };
                const namePrefix = `roles.${index}` as const;
                const fieldErrors = errors.roles?.[index];

                const currentDepartment = departments.find(
                  (department) =>
                    department.id === fieldValues[index].departmentId
                );
                const options = [
                  {
                    label: currentDepartment?.name ?? "UNKNOWN",
                    value: currentDepartment?.id ?? "UNKNOWN",
                  },
                  ...availableDepartments.map((department) => ({
                    label: department.name,
                    value: department.id,
                  })),
                ];

                return (
                  <React.Fragment key={roleField.id}>
                    {index > 0 && <Divider />}

                    <div
                      className={tw(
                        "w-full",
                        "grid",
                        "gap-6",
                        "grid-cols-1",
                        "md:grid-cols-2",
                        "md:items-end"
                      )}
                    >
                      <Controller
                        control={control}
                        name={`${namePrefix}.departmentId`}
                        defaultValue={roleField.departmentId}
                        render={({ field }) => (
                          <Select
                            className={tw("w-full")}
                            id={field.name}
                            name={field.name}
                            label={t(
                              "popup.teamMember.form.department.label",
                              "Department"
                            )}
                            options={options}
                            defaultValue={field.value}
                            onChange={(event) => {
                              field.onChange(event);

                              trigger("roles");
                            }}
                            errorMessage={fieldErrors?.departmentId?.message}
                            disabled={!currentDepartment?.canManage}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name={`${namePrefix}.role`}
                        defaultValue={roleField.role}
                        render={({ field }) => (
                          <Select
                            className={tw("w-full")}
                            id={field.name}
                            name={field.name}
                            label={t(
                              "popup.teamMember.form.role.label",
                              "Role"
                            )}
                            options={Object.values(DepartmentRoleEnum).map(
                              (value) => ({
                                label: (() => {
                                  switch (value) {
                                    case DepartmentRoleEnum.Manager:
                                      return t(
                                        "popup.teamMember.form.role.option.manager",
                                        "Manager"
                                      );

                                    case DepartmentRoleEnum.Member:
                                      return t(
                                        "popup.teamMember.form.role.option.member",
                                        "Member"
                                      );

                                    default:
                                      return "Unknown role";
                                  }
                                })(),
                                value,
                              })
                            )}
                            value={field.value}
                            onChange={field.onChange}
                            errorMessage={fieldErrors?.role?.message}
                            disabled={!currentDepartment?.canManage}
                          />
                        )}
                      />

                      {hasCheckmore && (
                        <>
                          <CheckBox
                            className={tw("w-full")}
                            id={`${namePrefix}.accessToCRC`}
                            {...register(`${namePrefix}.accessToCRC`)}
                            label={t(
                              "popup.teamMember.form.accessToCRC.label",
                              "Criminal record certificate access"
                            )}
                            disabled={!(hasOwnerAccess || hasAdminAccess)}
                          />

                          <CheckBox
                            className={tw("w-full")}
                            id={`${namePrefix}.accessToIdentity`}
                            {...register(`${namePrefix}.accessToIdentity`)}
                            label={t(
                              "popup.teamMember.form.accessToIdentity.label",
                              "National identity number access"
                            )}
                            disabled={!(hasOwnerAccess || hasAdminAccess)}
                          />
                        </>
                      )}
                    </div>

                    <div className={tw("block", { hidden: index === 0 })}>
                      {selectedDepartmentIds.length > 1 &&
                        currentDepartment?.canManage && (
                          <Button
                            id="member_popup-remove_role"
                            variant="tertiary"
                            size="sm"
                            LeadingIcon={HiOutlineTrash}
                            onClick={removeItem}
                          >
                            {t(
                              "popup.teamMember.form.removeRole",
                              "Remove role"
                            )}
                          </Button>
                        )}
                    </div>
                  </React.Fragment>
                );
              })
            )}

            {!isAdmin && availableDepartments.length > 0 && (
              <>
                <Divider />

                <Button
                  id="member_popup-add_role"
                  onClick={addDefaultRole}
                  variant="secondary"
                  size="md"
                  LeadingIcon={HiPlus}
                >
                  {t("popup.teamMember.form.addRole", "Add another role")}
                </Button>
              </>
            )}
          </div>
        )}
      </form>

      {!isOwner && (
        <Button
          id={
            isExisting
              ? "member_popup-update_member"
              : "member_popup-invite_member"
          }
          type="submit"
          form={formId}
          variant="primary"
          size="sm"
        >
          {isExisting
            ? t("popup.teamMember_update.form.submit", "Update member")
            : t("popup.teamMember_add.form.submit", "Invite member")}
        </Button>
      )}
    </>
  );
};
