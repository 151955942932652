import { useContext } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { StripeElementLocale, loadStripe } from "@stripe/stripe-js";

import { PaymentMethodFragment } from "graphql/fragments";
import { IntlContext } from "providers/i18n";
import { stripeUtils } from "utils";

import EditCardMethodForm from "./EditCardMethodForm";

interface Props {
  paymentMethod: PaymentMethodFragment;
}

export default ({ paymentMethod }: Props): JSX.Element => {
  const { currentLocale } = useContext(IntlContext);

  const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const stripePromise = stripeKey ? loadStripe(stripeKey) : null;

  return (
    <Elements
      options={{
        appearance: stripeUtils.appearance,
        locale: currentLocale.toLowerCase() as StripeElementLocale,
      }}
      stripe={stripePromise}
    >
      <EditCardMethodForm paymentMethod={paymentMethod} />
    </Elements>
  );
};
