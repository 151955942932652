/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  ERROR_FRAGMENT,
  ErrorFragment,
  SIGNATURE_FRAGMENT,
  SignatureFragment,
} from "graphql/fragments";
import { DOCUMENT, DocumentQuery } from "graphql/queries";

const DESTROY_SIGNATURE = gql`
  mutation ($id: ID!) {
    destroySignature(input: { id: $id }) {
      signature {
        ...SignatureFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${SIGNATURE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  signature: SignatureFragment;
  errors: ErrorFragment[];
}

interface DestroySignatureMutation {
  destroySignature: Payload | null;
}

export const useDestroySignatureMutation = () => {
  const [mutation, { data, loading }] = useMutation<
    DestroySignatureMutation,
    { id: string }
  >(DESTROY_SIGNATURE);

  const destroySignature = (id: string, onSuccess?: () => void) =>
    mutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            node() {
              const bulkDocument = cache.readQuery<DocumentQuery>({
                query: DOCUMENT,
              });
              if (!bulkDocument) return;

              const bulkSignatures = bulkDocument.node.bulkSignatures.filter(
                (signature) => signature.id !== id
              );

              cache.writeQuery<DocumentQuery>({
                query: DOCUMENT,
                data: {
                  node: {
                    ...bulkDocument.node,
                    bulkSignatures,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data }) => {
        if (data?.destroySignature?.signature && onSuccess) onSuccess();
      })
      .catch((_error: ApolloError) => null);

  return {
    destroySignature,
    isLoading: loading,
    signature: data?.destroySignature?.signature,
  };
};
