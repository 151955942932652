import { HiCreditCard, HiDocumentText } from "react-icons/hi";

import { StripeCardBrand } from "utils/stripe";
import { tw } from "utils/tw";

import amex from "assets/credit_cards/amex.svg";
import diners from "assets/credit_cards/diners.svg";
import discover from "assets/credit_cards/discover.svg";
import jcb from "assets/credit_cards/jcb.svg";
import mastercard from "assets/credit_cards/mastercard.svg";
import unionpay from "assets/credit_cards/unionpay.svg";
import visa from "assets/credit_cards/visa.svg";

interface Props {
  type: StripeCardBrand | "invoice";
}

export default ({ type }: Props) => {
  switch (type) {
    case "amex":
      return <img src={amex} alt={type} />;
    case "diners":
      return <img src={diners} alt={type} />;
    case "discover":
      return <img src={discover} alt={type} />;
    case "jcb":
      return <img src={jcb} alt={type} />;
    case "mastercard":
      return <img src={mastercard} alt={type} />;
    case "unionpay":
      return <img src={unionpay} alt={type} />;
    case "visa":
      return <img src={visa} alt={type} />;

    case "invoice":
      return <HiDocumentText className={tw("text-blue-900")} size={24} />;

    case "unknown":
    default:
      return <HiCreditCard className={tw("text-blue-900")} size={24} />;
  }
};
