/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  PAYMENT_METHOD_FRAGMENT,
  PaymentMethodFragment,
} from "graphql/fragments";
import { PAYMENT_METHODS, PaymentMethodsQuery } from "graphql/queries";

const DESTROY_PAYMENT_METHOD = gql`
  mutation ($id: ID!) {
    destroyPaymentMethod(input: { id: $id }) {
      paymentMethod {
        ...PaymentMethodFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${PAYMENT_METHOD_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  paymentMethod?: PaymentMethodFragment | null;
  errors: ErrorFragment[];
}

interface DestroyPaymentMethodMutation {
  destroyPaymentMethod: Payload | null;
}

export const useDestroyPaymentMethodMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.destroyedPaymentMethod",
    "Payment method removed successfully"
  );
  // Values for extraction
  // t("common:success.destroyedPaymentMethod", "Payment method removed successfully")

  const [mutation, { data, loading }] = useMutation<
    DestroyPaymentMethodMutation,
    { id: string }
  >(DESTROY_PAYMENT_METHOD);

  const destroyPaymentMethod = (
    id: string,
    onSuccess?: (paymentMethod: PaymentMethodFragment) => void
  ) =>
    mutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            paymentMethods() {
              const existing = cache.readQuery<PaymentMethodsQuery>({
                query: PAYMENT_METHODS,
              });
              if (!existing) return;

              const items = (existing.paymentMethods.items ?? []).filter(
                (method) => method.id !== id
              );

              cache.writeQuery<PaymentMethodsQuery>({
                query: PAYMENT_METHODS,
                data: {
                  paymentMethods: {
                    items,
                    total: existing.paymentMethods.total - 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.destroyPaymentMethod?.errors ?? [];
        const paymentMethod = data?.destroyPaymentMethod?.paymentMethod;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && paymentMethod && onSuccess(paymentMethod),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyPaymentMethod,
    isLoading: loading,
    paymentMethod: data?.destroyPaymentMethod?.paymentMethod,
    errors: data?.destroyPaymentMethod?.errors ?? [],
  };
};
