import { AccountDetails, Button, Spinner } from "components/common/basic";
import {
  Banner,
  CheckoutForm,
  PaymentMethod,
  TierSummary,
} from "components/common/composite";
import { AccountFragment, TierFragment } from "graphql/fragments";
import { useUpdateSubscriptionMutation } from "graphql/mutations";
import { usePaymentMethodsQuery } from "graphql/queries";
import { useFormatter } from "hooks";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  account: AccountFragment;
  tier: TierFragment;
  isUpgrade: boolean;
}

export default ({ account, tier, isUpgrade }: Props): JSX.Element => {
  const { paymentMethods } = usePaymentMethodsQuery();

  const { updateSubscription, isLoading } = useUpdateSubscriptionMutation();
  const { t } = useTranslation("common");
  const { getFormattedAddress } = useFormatter();

  if (!paymentMethods) return <Spinner />;

  const subscribe = () => updateSubscription(tier.id);
  const submitLabel = isUpgrade
    ? t("popup.updateSubscription_payment.upgrade", "Subscribe")
    : t("popup.updateSubscription_payment.downgrade", "Downgrade");

  const summary = (
    <div
      className={tw(
        "w-full",
        "rounded-lg",
        "bg-silver",
        "px-4",
        "py-6",
        "xl:p-10",
        "xl:w-auto"
      )}
    >
      <div className={tw("xl:w-80")}>
        <TierSummary tier={tier} />
      </div>
    </div>
  );

  const defaultMethod = paymentMethods.find(
    (paymentMethod) => paymentMethod.default
  );

  return (
    <div className={tw("space-y-8")}>
      {!isUpgrade && (
        <Banner
          variant="danger"
          title={t(
            "popup.updateSubscription_payment.downgradeBanner.title",
            "Are you sure you want to downgrade?"
          )}
          body={t(
            "popup.updateSubscription_payment.downgradeBanner.body",
            "Downgrading will result in the loss of access to premium features and may affect your team's current setup. Please confirm your decision to proceed."
          )}
        />
      )}

      <div
        className={tw(
          "flex",
          "flex-wrap-reverse",
          "gap-6",
          "xl:flex-nowrap",
          "xl:gap-24",
          "items-start"
        )}
      >
        <div className={tw("flex-1", "space-y-8")}>
          {tier.amount === 0 ? (
            <Button
              id="subscription_change_popup-save_changes"
              onClick={subscribe}
              disabled={isLoading}
            >
              {submitLabel}
            </Button>
          ) : paymentMethods.length === 0 ? (
            <CheckoutForm submitLabel={submitLabel} onSuccess={subscribe} />
          ) : (
            <>
              <AccountDetails
                email={account.email}
                organization={`${account.organizationName} / ${account.organizationNumber}`}
                address={
                  account.address && getFormattedAddress(account.address)
                }
              />

              <div className={tw("space-y-4")}>
                <h2 className={tw("text-deepBlue-900", "text-lg", "font-bold")}>
                  {t(
                    "popup.updateSubscription_payment.defaultMethod.heading",
                    "Default payment method"
                  )}
                </h2>

                {defaultMethod ? (
                  <PaymentMethod
                    paymentMethod={defaultMethod}
                    canEdit={false}
                  />
                ) : (
                  // This should never happen
                  <Spinner />
                )}
              </div>

              <Button
                id="subscription_change_popup-save_changes"
                onClick={subscribe}
                disabled={isLoading}
              >
                {submitLabel}
              </Button>
            </>
          )}
        </div>

        {summary}
      </div>
    </div>
  );
};
