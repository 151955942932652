import { useContext } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Input } from "components/common/basic";
import { PopupWrapper } from "components/common/popups";
import {
  ClientFragment,
  FreelanceProfileFragment,
  ReferencePersonFragment,
} from "graphql/fragments";
import {
  useUpdateClientMutation,
  useUpdateFreelanceProfileMutation,
} from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import {
  emailValidationSchema,
  phoneValidationSchema,
  referencePersonToAttributes,
} from "utils";
import { tw } from "utils/tw";

interface Props {
  hasPrevious: boolean;
  parent: ClientFragment | FreelanceProfileFragment;
  referencePerson: ReferencePersonFragment | null;
  onSuccess: (person: ReferencePersonFragment) => void;
}

export default ({
  parent,
  referencePerson,
  onSuccess,
  ...props
}: Props): JSX.Element => {
  const { updateClient } = useUpdateClientMutation();
  const { updateFreelanceProfile } = useUpdateFreelanceProfileMutation();
  const isClient = "clientType" in parent;
  const updateParent = isClient ? updateClient : updateFreelanceProfile;
  const companyName = isClient ? parent.name : parent.organizationName;

  const { closeAllPopups } = useContext(PopupContext);
  const { t } = useTranslation("common");
  const { reportErrors } = useErrorLogger();

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required(
        t(
          "referencePersonForm.name.error.required",
          "A name is required for a reference person"
        )
      )
      .test(
        "uniqueName",
        t(
          "referencePersonForm.name.error",
          "Reference people can not have the same name"
        ),
        (value) =>
          parent.referencePeople.filter(({ name }) => {
            const isEditedPerson = name === referencePerson?.name;
            if (isEditedPerson) return false;

            return name.toLowerCase() === value?.toLowerCase();
          }).length === 0
      ),
    email: emailValidationSchema(t)
      .required()
      .test(
        "uniqueEmail",
        t(
          "referencePersonForm.email.error",
          "Reference people can not have the same email"
        ),
        (value) =>
          parent.referencePeople.filter(({ email }) => {
            const isEditedPerson = email === referencePerson?.email;
            if (isEditedPerson) return false;

            return email.toLowerCase() === value?.toLowerCase();
          }).length === 0
      ),
    phone: phoneValidationSchema(t).nullable(),
    referenceNumber: Yup.string().nullable(),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<ReferencePersonFragment>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  reportErrors(errors);

  const onSubmit = (person: ReferencePersonFragment) => {
    const updatedReferencePeople = referencePerson
      ? parent.referencePeople.map((refPerson) =>
          refPerson.email === person.email ? person : refPerson
        )
      : [...parent.referencePeople, person];

    updateParent(
      {
        id: parent.id,
        attributes: {
          referencePeople: updatedReferencePeople.map(
            referencePersonToAttributes
          ),
        },
      },
      () => {
        onSuccess(person);

        closeAllPopups();
      }
    );
  };

  const formId = "referencePersonForm";

  return (
    <PopupWrapper
      {...props}
      variant="popup"
      title={
        referencePerson
          ? t("referencePersonForm_edit.title", "Edit reference person")
          : t("referencePersonForm_create.title", "Add reference person")
      }
      footerActions={[
        {
          id: "job_creation-cancel",
          onClick: closeAllPopups,
          variant: "tertiary",
          label: t("reference_person.cancel", "Cancel"),
        },
        {
          id: "reference_person-save",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("referencePersonForm.submit", "Save"),
        },
      ]}
    >
      <div className={tw("flex", "flex-col", "space-y-6")}>
        <p>
          <Trans
            ns="common"
            i18nKey="referencePersonForm.helperText"
            defaults="Please provide the name of your contact at <0>{{ companyName }}</0>. Your invoices will mention this person information."
            values={{ companyName }}
            components={[<strong />]}
          />
        </p>

        <form
          className={tw("w-full", "space-y-4")}
          id={formId}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            id="name"
            {...register("name")}
            label={t(
              "referencePersonForm.name.label",
              "Name of reference person"
            )}
            errorMessage={errors.name?.message}
            defaultValue={referencePerson?.name}
          />

          <Input
            id="email"
            {...register("email")}
            type="email"
            label={t("referencePersonForm.email.label", "Email address")}
            errorMessage={errors.email?.message}
            defaultValue={referencePerson?.email}
          />

          <Input
            id="phone"
            {...register("phone")}
            label={t("referencePersonForm.phone.label", "Phone number")}
            errorMessage={errors.phone?.message}
            defaultValue={referencePerson?.phone ?? ""}
          />

          <Input
            id="referenceNumber"
            {...register("referenceNumber")}
            label={t(
              "referencePersonForm.referenceNumber.label",
              "Reference number"
            )}
            errorMessage={errors.referenceNumber?.message}
            defaultValue={referencePerson?.referenceNumber ?? ""}
            hint={t("referencePersonForm.referenceNumber.hint", "Optional")}
          />
        </form>
      </div>
    </PopupWrapper>
  );
};
