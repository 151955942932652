import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "providers/Authentication";
import {
  Module,
  getDefaultRouteNameFromModule,
  useDefaultRoutePath,
  useRoutePaths,
} from "utils";

export default (): null => {
  const { module } = useParams<{ module: Module }>();
  const navigate = useNavigate();
  const getRoutePath = useRoutePaths();
  const defaultRoutePath = useDefaultRoutePath();
  const { session } = useContext(AuthContext);

  useEffect(() => {
    if (!module) return navigate(defaultRoutePath);

    const routeName = getDefaultRouteNameFromModule(
      module,
      session?.account.accountType
    );
    if (!routeName) return navigate(defaultRoutePath);

    navigate(getRoutePath({ module, routeName }));
  }, []);

  return null;
};
