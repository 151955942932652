import {
  HiCreditCard,
  HiOutlineCreditCard,
  HiOutlineShieldCheck,
  HiShieldCheck,
} from "react-icons/hi";

import { DepartmentRoleEnum, TeamRoleEnum } from "graphql/types";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  id: string;
  name: string;
  crc: boolean;
  identity: boolean;
  role?: DepartmentRoleEnum | TeamRoleEnum;
}

export default ({
  id,
  name,
  crc,
  identity,
  role,
}: Props): JSX.Element | null => {
  const { t } = useTranslation("account");

  const rowStyles = tw("flex", "space-x-2", "items-center");
  const getIconStyle = (isActive: boolean) =>
    tw("shrink-0", isActive ? "text-deepBlue-900" : "text-deepBlue-100");

  return (
    <li className={rowStyles} key={id}>
      {crc ? (
        <HiShieldCheck
          size={20}
          className={getIconStyle(true)}
          title={t(
            "accountSettings_org.team.table.departmentCell.icon.crc_active.helpText",
            "This user can access CRC"
          )}
        />
      ) : (
        <HiOutlineShieldCheck
          size={20}
          className={getIconStyle(false)}
          title={t(
            "accountSettings_org.team.table.departmentCell.icon.crc_inactive.helpText",
            "This user cannot access CRC"
          )}
        />
      )}

      {identity ? (
        <HiCreditCard
          size={20}
          className={getIconStyle(true)}
          title={t(
            "accountSettings_org.team.table.departmentCell.icon.identity_active.helpText",
            "This user can access identity"
          )}
        />
      ) : (
        <HiOutlineCreditCard
          size={20}
          className={getIconStyle(false)}
          title={t(
            "accountSettings_org.team.table.departmentCell.icon.identity_inactive.helpText",
            "This user cannot access identity"
          )}
        />
      )}

      <p className={tw("text-gray-900", "truncate")}>{name}</p>

      {role && (
        <p className={tw("text-deepBlue-500")}>
          {t(
            `accountSettings_org.team.table.departmentCell.role.${role.toLocaleLowerCase()}`,
            role[0] + role.slice(1).toLowerCase()
          )}
        </p>
      )}
    </li>
  );
};
