import {
  Control,
  FieldError,
  FieldErrors,
  Merge,
  UseFormRegister,
} from "react-hook-form";

import { Spinner } from "components/common/basic";
import { InputTable } from "components/common/composite";
import { useDepartmentsQuery } from "graphql/queries";
import { useTranslation } from "translations";

interface BulkRecipient {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface FormWithBulkRecipientsInputTable {
  people?: BulkRecipient[];
}

interface Props {
  control: Control<FormWithBulkRecipientsInputTable>;
  register: UseFormRegister<FormWithBulkRecipientsInputTable>;
  clearErrors: () => void;
  peopleErrors?: Merge<FieldError, FieldErrors<BulkRecipient[]>>;
}

export default ({
  control,
  register,
  clearErrors,
  peopleErrors,
}: Props): JSX.Element => {
  const { isLoading } = useDepartmentsQuery();

  const { t } = useTranslation("common");

  if (isLoading) return <Spinner />;

  return (
    <InputTable
      name="people"
      control={control}
      register={register}
      errors={peopleErrors}
      clearErrors={clearErrors}
      minItems={1}
      columns={[
        {
          key: "firstName",
          label: t(
            "popup.addBulkRecipients.form.firstName.label",
            "First name"
          ),
          width: "w-44",
          input: {
            type: "text",
            placeholder: t(
              "popup.addBulkRecipients.form.firstName.placeholder",
              "Add first name"
            ),
          },
        },
        {
          key: "lastName",
          label: t("popup.addBulkRecipients.form.lastName.label", "Last name"),
          width: "w-44",
          input: {
            type: "text",
            placeholder: t(
              "popup.addBulkRecipients.form.lastName.placeholder",
              "Add last name"
            ),
          },
        },
        {
          key: "email",
          label: t("popup.addBulkRecipients.form.email.label", "Email"),
          width: "w-44",
          input: {
            type: "email",
            placeholder: t(
              "popup.addBulkRecipients.form.email.placeholder",
              "Add email"
            ),
          },
        },
      ]}
      itemLabel={t("popup.addBulkRecipients.form.itemLabel", "Add recipient")}
      addItemLabel={t(
        "popup.addBulkRecipients.form.button.addPerson",
        "Add recipient"
      )}
      removeItemLabel={t(
        "popup.addBulkRecipients.form.button.removePerson",
        "Remove recipient"
      )}
    />
  );
};
