import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { AddressSearch } from "components/common/composite";
import { PopupVariant, PopupWrapper } from "components/common/popups";
import { useUpdateAccountMutation } from "graphql/mutations";
import { AddressAttributes } from "graphql/types";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

interface FormValues {
  address: AddressAttributes | null;
}

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
}

export default (props: Props): JSX.Element => {
  const { updateAccount } = useUpdateAccountMutation();

  const { t } = useTranslation("account");
  const { closeOnePopup } = useContext(PopupContext);

  const validationSchema = Yup.object({
    address: Yup.object()
      .shape({
        line1: Yup.string().required(),
        postalCode: Yup.string().required(),
        city: Yup.string().required(),
        country: Yup.string().required(),
      })
      .required()
      .nullable(),
  });

  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = (attributes: FormValues) =>
    updateAccount({ attributes }, closeOnePopup);

  const formId = "addressForm";

  return (
    <PopupWrapper
      {...props}
      nameSpace="account"
      title={t(
        "accountSettings.addressPopup.heading",
        "Add a postal or visiting address"
      )}
      footerActions={[
        {
          id: "accountSettings-add_address",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("accountSettings.addressPopup.button.submit", "Save"),
          disabled: !isValid,
        },
      ]}
    >
      <form onSubmit={handleSubmit(onSubmit)} id={formId}>
        <Controller
          control={control}
          name="address"
          render={({ field }) => (
            <AddressSearch
              label={t("accountSettings.addressPopup.address.label", "Address")}
              address={field.value ?? undefined}
              setAddress={field.onChange}
              errorMessage={
                errors.address &&
                t(
                  "freelanceProfileForm_individual.address.error.required",
                  "Address is required"
                )
              }
              country={["no"]}
            />
          )}
        />
      </form>
    </PopupWrapper>
  );
};
