import { Appearance } from "@stripe/stripe-js";

const appearance: Appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#071B3B",
    colorText: "#4E4E4E",
    colorDanger: "#E52322",
    fontFamily: "Mulish, sans-serif",
    fontSizeBase: "1rem",
    spacingUnit: "0.25rem",
    borderRadius: "0.375rem",
    gridColumnSpacing: "1.5rem",
    gridRowSpacing: "1.5rem",
  },
  rules: {
    ".Input": {
      borderColor: "#A7A7A7",
      padding: "8px 12px",
    },
    ".Input:focus": {
      borderColor: "#222222",
      boxShadow: "0 0 0 1px #222222",
    },
    ".Input--invalid": {
      backgroundColor: "#FCE9E9",
    },
    ".Error": {
      padding: "8px 0 0 0",
    },
  },
};

export type StripeCardBrand =
  | "visa"
  | "mastercard"
  | "amex"
  | "discover"
  | "diners"
  | "jcb"
  | "unionpay"
  | "unknown";

export default { appearance };
