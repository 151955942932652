import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Button, Input } from "components/common/basic";
import { useVerifyPhoneNumberMutation } from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { Trans, useTranslation } from "translations";
import { confirmationCodeSchema } from "utils";
import { tw } from "utils/tw";

interface FormValues {
  code: string;
}

interface Props {
  phoneNumber: {
    countryCode: string;
    number: string;
  };
  onSuccessfulValidation: () => void;
}

export const ValidateSMSCodeForm = ({
  phoneNumber: { countryCode, number },
  onSuccessfulValidation,
}: Props): JSX.Element => {
  const { verifyPhoneNumber, isLoading } = useVerifyPhoneNumberMutation();

  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    code: confirmationCodeSchema(t).required(),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = async ({ code }: FormValues) => {
    await verifyPhoneNumber({ countryCode, number, token: code });

    onSuccessfulValidation();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={tw("flex", "space-x-4", "items-start")}
      >
        <Input
          id="code"
          {...register("code")}
          label={t(
            "popup.phoneNumberVerification.validateSMSCode.smsCode.label",
            "Your 5-digit code"
          )}
          errorMessage={errors.code?.message}
        />

        <Button
          id="validate_sms_code_form-submit_code"
          type="submit"
          variant="primary"
          size="md"
          disabled={isLoading}
          className={tw("mt-6")}
        >
          <Trans
            ns="common"
            i18nKey="popup.phoneNumberVerification.validateSMSCode.button.submit"
          >
            Submit the code
          </Trans>
        </Button>
      </form>

      <p className={tw("text-sm")}>
        <Trans
          ns="common"
          i18nKey="popup.phoneNumberVerification.validateSMSCode.codeArrivalInformation"
        >
          The code may take a couple of minutes to arrive, depending on your
          network connection.
        </Trans>
      </p>
    </>
  );
};
