import { useState } from "react";

import IntervalToggle from "components/login/pages/TierSelection/IntervalToggle";
import Tiers from "components/login/pages/TierSelection/Tiers";
import { TierFragment } from "graphql/fragments";
import { PriceIntervalEnum } from "graphql/types";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  currentTier: TierFragment;
  onSelect: (tier: TierFragment) => void;
}

export default ({ currentTier, onSelect }: Props): JSX.Element => {
  const [interval, setInterval] = useState(
    currentTier.amount === 0 ? PriceIntervalEnum.Year : currentTier.interval
  );

  const { t } = useTranslation("common");

  return (
    <div className={tw("w-full")}>
      <div className={tw("pt-8", "flex", "justify-center")}>
        <IntervalToggle
          left={{
            label: t("popup.updateSubscription.interval.monthly", "Monthly"),
            value: PriceIntervalEnum.Month,
          }}
          right={{
            label: t("popup.updateSubscription.interval.yearly", "Yearly"),
            value: PriceIntervalEnum.Year,
          }}
          selected={interval}
          setSelected={setInterval}
        />
      </div>

      <div className={tw("mt-8", "lg:mt-0", "space-y-8")}>
        <Tiers
          currency={currentTier.currency}
          interval={interval}
          currentTier={currentTier}
          onSelect={onSelect}
        />
      </div>
    </div>
  );
};
