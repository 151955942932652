import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import {
  PAYMENT_METHOD_FRAGMENT,
  PaymentMethodFragment,
} from "graphql/fragments";
import { QueryPaymentMethodsArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const PAYMENT_METHODS = gql`
  query ($page: Int, $perPage: Int) {
    paymentMethods(page: $page, perPage: $perPage) {
      exportPath
      items {
        ...PaymentMethodFragment
      }
      total
    }
  }
  ${PAYMENT_METHOD_FRAGMENT}
`;

export type PaymentMethodsQuery = {
  paymentMethods: PaginatedPayload<PaymentMethodFragment>;
};

type QueryArgs = Pick<QueryPaymentMethodsArgs, "page" | "perPage">;

export const usePaymentMethodsQuery = (args: QueryArgs = {}) => {
  const { data, loading: isLoading } = useQuery<PaymentMethodsQuery, QueryArgs>(
    PAYMENT_METHODS,
    { variables: { ...args } }
  );

  const paymentMethods = data?.paymentMethods.items?.filter(Boolean) ?? [];

  return { paymentMethods, isLoading };
};

export const usePaymentMethodsLazyQuery = () => {
  const [getPaymentMethodsQuery, { data, loading: isLoading, called }] =
    useLazyQuery<PaymentMethodsQuery, QueryArgs>(PAYMENT_METHODS, {
      fetchPolicy: "network-only",
    });

  const exportPath = data?.paymentMethods.exportPath;
  const paymentMethods = data?.paymentMethods.items?.filter(Boolean) ?? [];
  const total = data?.paymentMethods.total ?? 0;

  const resolveRef =
    useRef<(paymentMethods?: PaymentMethodFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(paymentMethods);
      resolveRef.current = undefined;
    }
  }, [paymentMethods, called, isLoading]);

  const getPaymentMethods = async (args?: QueryArgs) => {
    getPaymentMethodsQuery({ variables: args });

    return new Promise<PaymentMethodFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getPaymentMethods,
    exportPath,
    paymentMethods,
    total,
    isLoading,
    called,
  };
};
