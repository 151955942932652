import { useContext } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Textarea } from "components/common/basic";
import { useCancelDocumentsMutation } from "graphql/mutations";
import { DocumentsFilter } from "graphql/types";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import { PopupWrapper } from "../PopupWrapper";
import useVariantText from "./useVariantText";

interface FormValues {
  message: string;
}

interface BaseProps {
  hasPrevious: boolean;
}
export interface SomeDocumentsProps {
  ids: string[];
  onSuccess?: () => void;
}
export interface AllDocumentsProps {
  filter: DocumentsFilter;
  onSuccess?: () => void;
}

type Props = BaseProps & (SomeDocumentsProps | AllDocumentsProps);

export default ({ onSuccess, ...props }: Props): JSX.Element => {
  const isSomeDocuments = Object.prototype.hasOwnProperty.call(props, "ids");
  const ids = isSomeDocuments ? (props as SomeDocumentsProps).ids : [];
  const filter = !isSomeDocuments ? (props as AllDocumentsProps).filter : {};

  const { t } = useTranslation("common");
  const { closeAllPopups } = useContext(PopupContext);

  const { title, subTitle } = useVariantText(ids.length);

  const { cancelDocuments } = useCancelDocumentsMutation();

  const validationSchema = Yup.object({
    message: Yup.string().required(
      t(
        "popup.cancelAction.errors.message.required",
        "A cancellation reason is mandatory"
      )
    ),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = (attributes: FormValues) =>
    cancelDocuments(
      { ...(isSomeDocuments ? { ids } : { filter }), attributes },
      () => {
        onSuccess?.();

        closeAllPopups();
      }
    );

  const formId = "cancelDocumentForm";

  return (
    <PopupWrapper
      {...props}
      title={title}
      subTitle={subTitle}
      variant="popup"
      footerActions={[
        {
          id: "cancel_documents_popup-cancel",
          onClick: closeAllPopups,
          variant: "tertiary",
          label: t("popup.cancelAction.closePopup.label", "Cancel"),
        },
        {
          id: "cancel_documents_popup-confirm",
          type: "submit",
          form: formId,
          variant: "danger",
          label: t("popup.cancelAction.submit.label", "Submit and cancel"),
        },
      ]}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        className={tw("w-full")}
      >
        <Textarea
          label={t("popup.cancelAction.message.label", "Cancellation reason")}
          id="message"
          {...register("message")}
          rows={5}
          errorMessage={errors.message?.message}
        />
      </form>
    </PopupWrapper>
  );
};
