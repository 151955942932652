/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";

const SEND_TEAM_WELCOME = gql`
  mutation ($id: ID!) {
    sendTeamWelcome(input: { id: $id }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface CreateSessionOtpMutation {
  sendTeamWelcome: Payload | null;
}

export const useSendTeamWelcomeMutation = (
  onSuccess?: (id: string) => void
) => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.sentTeamWelcome",
    "A welcome email was sent successfully"
  );
  // Values for extraction
  // t("common:success.sentTeamWelcome", "A welcome email was sent successfully")

  const [mutation, { data, loading }] = useMutation<
    CreateSessionOtpMutation,
    { id: string }
  >(SEND_TEAM_WELCOME);

  const sendTeamWelcome = (id: string) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.sendTeamWelcome?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && onSuccess(id),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    sendTeamWelcome,
    isLoading: loading,
    errors: data?.sendTeamWelcome?.errors ?? [],
  };
};
