import { tw } from "utils/tw";

interface Option<T> {
  label: string;
  value: T;
}

interface Props<T> {
  left: Option<T>;
  right: Option<T>;
  selected: T;
  setSelected: (option: T) => void;
}

export default <T,>({
  left,
  right,
  selected,
  setSelected,
}: Props<T>): JSX.Element => {
  const containerStyles = tw(
    "relative",
    "grid",
    "grid-cols-2",
    "gap-1",
    "bg-deepBlue-50",
    "border-deepBlue-50",
    "border-2",
    "rounded-full"
  );

  const indicatorStyles = tw(
    "z-0",
    "absolute",
    "bg-deepBlue-900",
    "h-full",
    "w-1/2",
    "rounded-full",
    "transition-all",
    "ease-in-out",
    "duration-200",
    { "translate-x-full": selected === right.value }
  );

  const getButtonStyles = (isActive: boolean) =>
    tw(
      "z-10",
      "py-1",
      "px-4",
      "text-sm",
      "rounded-full",
      "transition-colors",
      "ease-in-out",
      "duration-200",
      "focus:outline-none",
      "focus:ring-2",
      "focus:ring-offset-2",
      "focus:ring-deepBlue-900",
      isActive
        ? tw("cursor-default", "text-white")
        : tw("cursor-pointer", "text-deepBlue-500", "hover:text-deepBlue-700")
    );

  return (
    <div className={containerStyles}>
      <span className={indicatorStyles} />

      <button
        className={getButtonStyles(selected === left.value)}
        onClick={() => setSelected(left.value)}
      >
        {left.label}
      </button>

      <button
        className={getButtonStyles(selected === right.value)}
        onClick={() => setSelected(right.value)}
      >
        {right.label}
      </button>
    </div>
  );
};
