import { IdentityCountryCodeEnum } from "graphql/types";

import flag_dk from "assets/symbols/flag_dk_s.svg";
import flag_fi from "assets/symbols/flag_fi_s.svg";
import flag_no from "assets/symbols/flag_no_s.svg";
import flag_se from "assets/symbols/flag_se_s.svg";

export default (country: IdentityCountryCodeEnum): string => {
  switch (country) {
    case IdentityCountryCodeEnum.No:
      return flag_no;

    case IdentityCountryCodeEnum.Se:
      return flag_se;

    case IdentityCountryCodeEnum.Fi:
      return flag_fi;

    case IdentityCountryCodeEnum.Dk:
      return flag_dk;

    default:
      return ((_country: never) => flag_no)(country);
  }
};
