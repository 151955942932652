import { useContext } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Input, Textarea } from "components/common/basic";
import { PopupWrapper } from "components/common/popups";
import { DepartmentFragment } from "graphql/fragments";
import {
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
} from "graphql/mutations";
import { useDepartmentsQuery } from "graphql/queries";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface FormValues {
  name: string;
  description: string;
}

interface Props {
  hasPrevious: boolean;
  department?: DepartmentFragment;
  onSuccess?: (department: DepartmentFragment) => void;
}

export default ({ department, onSuccess, ...props }: Props): JSX.Element => {
  const { departments } = useDepartmentsQuery();
  const { updateDepartment } = useUpdateDepartmentMutation();
  const { createDepartment } = useCreateDepartmentMutation();

  const { closeOnePopup } = useContext(PopupContext);
  const { t } = useTranslation("common");
  const { reportErrors } = useErrorLogger();

  const descriptionMaxLength = 280;

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .test(
        "unique",
        t(
          "department.name.error.taken",
          "A department with this name already exists"
        ),
        (value) =>
          departments.filter(
            ({ id, name }) => id !== department?.id && name === value
          ).length === 0
      )
      .required(t("department.name.error.required", "A name is required")),
    description: Yup.string()
      .trim()
      .max(
        descriptionMaxLength,
        t(
          "department.description.error.maxLength",
          "A description must be 280 characters or less"
        )
      )
      .required(
        t("department.description.error.required", "A description is required")
      ),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  reportErrors(errors);

  const onSubmit = (attributes: FormValues) => {
    const handleSuccess = (departmentData: DepartmentFragment) => {
      onSuccess?.(departmentData);

      closeOnePopup();
    };

    if (department) updateDepartment(department.id, attributes, handleSuccess);
    else createDepartment(attributes, handleSuccess);
  };

  const formId = "departmentForm";

  return (
    <PopupWrapper
      {...props}
      variant="popup"
      title={
        department
          ? t("departmentForm_edit.title", "Edit Department")
          : t("departmentForm_create.title", "Add Department")
      }
      footerActions={[
        {
          id: "department_popup-cancel",
          onClick: closeOnePopup,
          variant: "tertiary",
          label: t("departmentForm.cancel", "Cancel"),
        },
        {
          id: "department_popup-submit",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("departmentForm.submit", "Save"),
        },
      ]}
    >
      <form
        className={tw("w-full", "space-y-8")}
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          id="name"
          {...register("name")}
          label={t("departmentForm.name.label", "Department name")}
          errorMessage={errors.name?.message}
          defaultValue={department?.name}
        />

        <Textarea
          id="description"
          {...register("description")}
          label={t(
            "departmentForm.description.label",
            "Department description"
          )}
          errorMessage={errors.description?.message}
          defaultValue={department?.description ?? ""}
          maxLength={descriptionMaxLength}
        />
      </form>
    </PopupWrapper>
  );
};
