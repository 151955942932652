import { PropsWithChildren, useContext } from "react";

import { AuthContext } from "providers/Authentication";
import { tw } from "utils/tw";

import BannerSection from "../Layout/BannerSection";
import BackHeader from "./BackHeader";
import LogoHeader from "./LogoHeader";
import ProgressSideMenu from "./ProgressSideMenu";
import SimpleHeader from "./SimpleHeader";
import WizardContext from "./WizardContext";

interface Props {
  header: JSX.Element;
  title: string;
  description: string | JSX.Element;
  exitWizard?: () => void;
  hideLogout?: boolean;
  unbranded?: boolean;
}

export default ({
  header,
  title,
  description,
  exitWizard,
  hideLogout = false,
  unbranded = false,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  const { isLoggedIn, logout } = useContext(AuthContext);
  const { steps, currentStep } = useContext(WizardContext);

  return (
    <div className={tw("flex", "flex-col", "md:h-screen")}>
      <BannerSection />

      {exitWizard ? (
        <BackHeader goBack={exitWizard} unbranded />
      ) : unbranded ? (
        <SimpleHeader />
      ) : (
        <LogoHeader />
      )}

      <div className={tw("flex-1", "flex", "flex-col", "md:flex-row")}>
        <ProgressSideMenu
          header={header}
          title={title}
          description={description}
          currentStep={currentStep}
          steps={steps}
          logout={!hideLogout && isLoggedIn ? logout : undefined}
        />

        <div
          className={tw(
            "md:flex-1",
            "md:h-full",
            "md:shrink-0",
            "md:overflow-hidden",
            "pt-12",
            "px-4",
            "flex",
            "justify-center"
          )}
        >
          <div className={tw("w-full", "max-w-4xl")}>
            {children}

            <span className={tw("block", "h-10")} />
          </div>
        </div>
      </div>
    </div>
  );
};
