import { useContext, useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddressElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import * as Yup from "yup";

import { Button, Input, RadioPill } from "components/common/basic";
import { PaymentMethodInfo } from "components/common/composite";
import { PaymentMethodFragment } from "graphql/fragments";
import { useUpdatePaymentMethodMutation } from "graphql/mutations";
import { EntityTypeEnum } from "graphql/types";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface FormValues {
  invoiceEmail?: string;
}

interface Props {
  paymentMethod: PaymentMethodFragment;
}

export default ({ paymentMethod }: Props): JSX.Element => {
  const [entityType, setEntityType] = useState(EntityTypeEnum.Organization);

  const { updatePaymentMethod, isLoading } = useUpdatePaymentMethodMutation();

  const { t } = useTranslation("common");
  const { closeOnePopup } = useContext(PopupContext);

  const stripe = useStripe();
  const elements = useElements();

  const validationSchema = Yup.object<FormValues>().shape({
    invoiceEmail: Yup.string().email(
      t(
        "popup.editPaymentMethod_card.invoiceEmail.error.format",
        "Incorrect email format"
      )
    ),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: {
      invoiceEmail: paymentMethod.email ?? "",
    },
    resolver: yupResolver(validationSchema),
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = ({ invoiceEmail }: FormValues) => {
    if (!stripe || !elements) return;

    const addressElement = elements.getElement("address");
    if (!addressElement) return;

    addressElement.getValue().then(
      ({
        complete,
        value: {
          name,
          address: { postal_code: postalCode, state: city, ...address },
        },
      }) => {
        if (!complete) return;

        const attributes = {
          email: invoiceEmail,
          name,
          address: { ...address, postalCode, city },
        };
        updatePaymentMethod(paymentMethod.id, attributes, closeOnePopup);
      }
    );
  };

  return (
    <form
      onSubmit={(event) => {
        // Trigger validation
        elements?.submit();

        handleSubmit(onSubmit)(event);
      }}
      className={tw("space-y-6")}
    >
      <PaymentMethodInfo paymentMethod={paymentMethod} />

      <div className={tw("space-x-3")}>
        {[
          {
            label: t(
              "popup.editPaymentMethod_card.addressType.organization",
              "Organisation"
            ),
            value: EntityTypeEnum.Organization,
          },
          {
            label: t(
              "popup.editPaymentMethod_card.addressType.individual",
              "Individual"
            ),
            value: EntityTypeEnum.Individual,
          },
        ].map((option) => (
          <RadioPill
            key={option.value}
            id={option.value}
            label={option.label}
            value={option.value}
            checked={entityType === option.value}
            onChange={() => setEntityType(option.value)}
          />
        ))}
      </div>

      <AddressElement
        key={entityType}
        options={{
          mode: "billing",
          display: {
            name:
              entityType == EntityTypeEnum.Organization
                ? "organization"
                : "full",
          },
          autocomplete: process.env.REACT_APP_GOOGLE_PLACES_API_KEY
            ? {
                mode: "google_maps_api",
                apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
              }
            : undefined,
          defaultValues: {
            address: {
              line1: paymentMethod.address.line1,
              line2: paymentMethod.address.line2,
              city: paymentMethod.address.city,
              state: paymentMethod.address.county,
              postal_code: paymentMethod.address.postalCode,
              country: paymentMethod.address.country ?? "",
            },
            name: `${paymentMethod.name}`,
          },
        }}
      />

      <div className={tw("w-full", "bg-white", "space-y-6")}>
        <Input
          id="invoiceEmail"
          {...register("invoiceEmail")}
          label={t(
            "cpopup.editPaymentMethod_card.invoiceEmail.label",
            "Invoice email"
          )}
          hint={t(
            "cpopup.editPaymentMethod_card.invoiceEmail.hint",
            "Optional"
          )}
          errorMessage={errors.invoiceEmail?.message}
          fullWidth
        />
      </div>

      <Button id="checkout-submit_form" type="submit" disabled={isLoading}>
        {t("cpopup.editPaymentMethod_card.submit", "Save")}
      </Button>
    </form>
  );
};
