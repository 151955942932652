import { gql } from "@apollo/client";

import {
  Account,
  Check,
  CheckList,
  CheckRequest,
  Department,
  Document,
  Person,
  User,
} from "graphql/types";

import { ADDRESS_FRAGMENT, AddressFragment } from "./address";
import { NAME_FRAGMENT, NameFragment } from "./name";

export const PERSON_FRAGMENT = gql`
  fragment PersonFragment on Person {
    id
    email
    invitationName {
      ...NameFragment
    }
    legalName {
      ...NameFragment
    }
    preferredName {
      ...NameFragment
    }
    phone
    archivedAt
    teamRole
    checkListCount
    documentCount
    personalAccount {
      id
      symbolDisplayPath
      role
      defaultIdentity {
        identifier
      }
      primaryUser {
        confirmed
      }
      address {
        ...AddressFragment
      }
    }
    checkLists {
      id
      status
      jobRole
      lastContactedAt
      requestedAt
      checkRequest {
        id
        type
      }
      checks {
        id
        status
      }
      department {
        id
        name
      }
    }
    documents {
      id
      type
      status
      name
      sentAt
      interactedAt
      signedAt
    }
    departments {
      id
      name
    }
  }
  ${ADDRESS_FRAGMENT}
  ${NAME_FRAGMENT}
`;

interface PersonalAccount
  extends Pick<Account, "id" | "symbolDisplayPath" | "role"> {
  defaultIdentity?: {
    identifier: string;
  };
  primaryUser?: Pick<User, "confirmed">;
  address?: AddressFragment;
}

interface PersonCheckList
  extends Pick<
    CheckList,
    "id" | "status" | "jobRole" | "lastContactedAt" | "requestedAt"
  > {
  checkRequest: Pick<CheckRequest, "id" | "type">;
  checks: Pick<Check, "id" | "status">[];
  department?: Pick<Department, "id" | "name">;
}

interface PersonDocument
  extends Pick<
    Document,
    "id" | "type" | "status" | "name" | "sentAt" | "interactedAt" | "signedAt"
  > {}

export interface PersonFragment
  extends Pick<
    Person,
    | "id"
    | "email"
    | "invitationName"
    | "legalName"
    | "preferredName"
    | "phone"
    | "archivedAt"
    | "teamRole"
    | "checkListCount"
    | "documentCount"
  > {
  invitationName: NameFragment;
  legalName?: NameFragment;
  preferredName?: NameFragment;
  personalAccount: PersonalAccount;
  checkLists: PersonCheckList[];
  documents: PersonDocument[];
  departments: Pick<Department, "id" | "name">[];
}
