/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  DOCUMENT_FRAGMENT,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { Base64AttachmentAttributes } from "graphql/types";

const UPLOAD_SIGNATURES = gql`
  mutation ($id: ID!, $file: Base64AttachmentAttributes!) {
    uploadSignatures(input: { documentId: $id, file: $file }) {
      document {
        ...DocumentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  document: DocumentFragment;
  errors: ErrorFragment[];
}

interface UploadSignaturesMutation {
  uploadSignatures: Payload | null;
}

export const useUploadSignaturesMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UploadSignaturesMutation,
    { id: string; file: Base64AttachmentAttributes }
  >(UPLOAD_SIGNATURES);

  const uploadSignatures = (
    variables: { id: string; file: Base64AttachmentAttributes },
    onSuccess?: () => void
  ) =>
    mutation({ variables })
      .then(({ data, errors }) => {
        const dataErrors = data?.uploadSignatures?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    uploadSignatures,
    isLoading: loading,
    errors: data?.uploadSignatures?.errors ?? [],
  };
};
