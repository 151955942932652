/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { TIER_FRAGMENT, TierFragment } from "graphql/fragments";
import { QueryTiersArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const TIERS = gql`
  query ($page: Int, $perPage: Int, $filter: TiersFilter) {
    tiers(page: $page, perPage: $perPage, filter: $filter) {
      exportPath
      items {
        ...TierFragment
      }
      total
    }
  }
  ${TIER_FRAGMENT}
`;

export type TiersQuery = {
  tiers: PaginatedPayload<TierFragment>;
};

type QueryArgs = Pick<QueryTiersArgs, "page" | "perPage" | "filter">;

export const useTiersQuery = (args: QueryArgs) => {
  const {
    data,
    loading: isLoading,
    called,
  } = useQuery<TiersQuery, QueryArgs>(TIERS, {
    variables: { ...args },
  });

  const tiers = data?.tiers.items?.filter(Boolean) ?? [];
  const total = data?.tiers.total ?? 0;

  return { tiers, total, isLoading, called };
};

export const useTiersLazyQuery = () => {
  const [getTiersQuery, { data, loading: isLoading, called }] = useLazyQuery<
    TiersQuery,
    QueryArgs
  >(TIERS, {
    fetchPolicy: "network-only",
  });

  const tiers = data?.tiers.items?.filter(Boolean) ?? [];
  const total = data?.tiers.total ?? 0;

  const resolveRef = useRef<(tiers?: TierFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(tiers);
      resolveRef.current = undefined;
    }
  }, [tiers, called, isLoading]);

  const getTiers = async (args: QueryArgs) => {
    const variables = { ...args };

    getTiersQuery({ variables });

    return new Promise<TierFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getTiers, tiers, total, isLoading };
};
